import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import PhoneInput from "react-phone-input-2";
import MDButton from "components/MDButton";
import { FileUpload } from "components/FileUpload";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import "react-day-picker/dist/style.css";
import { createStudents } from "../../utils/requests/students/studentsget";
import { SnackbarProvider, useSnackbar } from "notistack";
import { profile } from "context/profile";
import { postTeachers } from "utils/requests/teachers/teacherspost";
import { getSubject } from "utils/requests/subjects/subjectRequests";
import { LoadinSpiner } from "components/LoadingSpinner";
import { snackbar } from "../awesome_snackbar/snackbar";
const AddingComp = (props) => {
  const { isStaff } = props;
  const [showCalendar, setShowCalendar] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [parentFirstName, setParentFirstName] = useState("");
  const [parentLastName, setParentLastName] = useState("");
  const [gradeLevel, setGradeLevel] = useState("");
  const [gradeSection, setGradeSection] = useState("");
  const [phone, setPhone] = useState("");
  const [parentEmail, setParentEmail] = useState("");
  const [dateValue, setDateValue] = useState(dayjs());
  const [gender, setGender] = useState("");
  const [errors, setErrors] = useState({});
  const [subjects, setSubjects] = useState([{}]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    handleSubjectFetch();
  }, []);
  const validateFields = () => {
    const newErrors = {};
    if (!firstName) newErrors.firstName = "First name is required";
    if (!lastName) newErrors.lastName = "Last name is required";
    if (!parentFirstName) newErrors.parentFirstName = "Parent first name is required";
    if (!parentLastName) newErrors.parentLastName = "Parent last name is required";
    if (!gradeLevel) newErrors.gradeLevel = "Grade level is required";
    if (!gradeSection) newErrors.gradeSection = "Grade section is required";
    if (!phone) newErrors.phone = "Phone number is required";
    if (!parentEmail) newErrors.parentEmail = "Parent email is required";
    if (!gender) newErrors.gender = "Gender is required";
    return newErrors;
  };

  const handleSubmitStudent = async () => {
    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      // Submit the form
      await dataOrganization();
    }
  };
  const handleSubmit = async () => {
    const first_name = document.getElementById("first-name").value || "";
    const last_name = document.getElementById("last-name").value || "";
    const phone_number = phone;
    const email = document.getElementById("email").value || "";
    const subject_id = document.getElementById("subject").value;
    const educational_level = document.getElementById("educational_level").value || "";
    const role = document.getElementById("role").value || "";

    const data = {
      first_name,
      last_name,
      phone_number,
      email,
      subject_id,
      educational_level,
      role,
    };
    const response = await postTeachers(data);
    console.log(response.success);
    if (response.success) {
      snackbar("success", "staff Profile Successfuly created!");

      document.getElementById("first-name").value = "";
      document.getElementById("last-name").value = "";
      document.getElementById("email").value = "";
      document.getElementById("subject").value = "";
      document.getElementById("educational_level").value = "";
      document.getElementById("role").value = "";

      setPhone("");
    } else {
      snackbar("error", "Failed to create staff Profile!");
    }
  };
  const dataOrganization = async () => {
    try {
      const formData = {
        first_name: document.getElementById("first-name").value || "",
        last_name: document.getElementById("last-name").value || "",
        first_namep:
          document.getElementById("parent-first-name").value ||
          document.getElementById("last-name").value,
        last_namep: document.getElementById("parent-last-name").value || "",
        grade: document.getElementById("grade_level").value || "",
        section: document.getElementById("grade_section").value || "",
        phone_numberp: phone,
        emailp: document.getElementById("parent-email").value || "",
        gender:
          document.querySelector('input[name="row-radio-buttons-group"]:checked').value || "m",
        birth_date: dateValue.format("YYYY-MM-DD") || "2002-01-01",
      };
      const response = await createStudents(formData);
      console.log(response.success);

      if (response.data.success) {
        snackbar("success", "Student Profile Successfuly created!");
      } else {
        snackbar("error", "Failed to create student Profile!");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const snackBar = (variant, message) => {
    enqueueSnackbar(message, { variant });
  };
  const handleKeyPress = (event, nextFieldId) => {
    if (event.key === "Enter") {
      event.preventDefault();
      document.getElementById(nextFieldId)?.focus();
    }
  };
  const handleCalendar = () => {
    setShowCalendar(!showCalendar);
  };
  const handleSubjectFetch = async () => {
    setIsLoading(true);
    const response = await getSubject();
    setIsLoading(false);
    console.log(response);
    if (response.success) setSubjects(response.data);
    console.log(subjects);
  };
  const timestamp = Date.now();
  const isoDateString = new Date(timestamp).toISOString();
  console.log(`isStaff ${isStaff}`);
  return isStaff ? (
    <MDBox display="flex" justifyContent="space-around">
      <MDBox pt={4} flex={1} mx={3}>
        <SnackbarProvider />
        <MDBox mb={2}>
          <MDInput
            id="first-name"
            label="First name"
            style={{ width: "45%" }}
            onKeyPress={(event) => handleKeyPress(event, "last-name")}
          />
          <MDInput
            id="last-name"
            type="last-name"
            label="Last name"
            style={{ width: "45%", marginLeft: "5%" }}
            onKeyPress={(event) => handleKeyPress(event, "phone_input")}
          />
        </MDBox>
        <MDBox mb={2}>
          <PhoneInput
            id="phone_input"
            country={"et"}
            value={phone}
            placeholder="+ (251) 985-042-445"
            onChange={(numbers) => {
              setPhone(numbers);
            }}
            preferredCountries={["et", "ke", "dj", "sd"]}
            enableSearch={true}
            countryCodeEditable={false}
            PhoneInputCountryFlag-borderColor={{ color: "brown" }}
            inputStyle={{
              width: "95%",
              height: "45px",
            }}
            autocompleteSearch={true}
            onKeyPress={(event) => handleKeyPress(event, "email")}
          />
        </MDBox>
        <MDBox>
          <MDInput
            id="email"
            type="email"
            label="Email"
            style={{ width: "95%" }}
            onKeyPress={(event) => handleKeyPress(event, "subject")}
          />
        </MDBox>

        <select
          id="subject"
          style={{
            width: "95%",
            fontSize: "14px",
            padding: "10px",
            borderRadius: "5px",
            color: "grey",
            border: "1px solid lightgrey",
            marginTop: 20,
            onKeyPress: (event) => handleKeyPress(event, "educational_level"),
          }}
        >
          <option value="">subject</option>
          {!isLoading ? (
            subjects.map((subject) => {
              console.log(subject);
              return (
                <option key={subject.subject_id} value={subject.subject_id}>
                  {subject.name}
                </option>
              );
            })
          ) : (
            <LoadinSpiner />
          )}
        </select>
        <select
          id="educational_level"
          style={{
            width: "95%",
            fontSize: "14px",
            padding: "10px",
            borderRadius: "5px",
            color: "grey",
            border: "1px solid lightgrey",
            marginTop: 20,
          }}
        >
          <option value="">Educational Level</option>
          <option value="High School">High School</option>
          <option value="Diploma">Diploma</option>
          <option value="Associate Degree">Associate Degree</option>
          <option value="Bachelor of Degree">Bachelor of Degree</option>
          <option value="Master of Science">Master of Science (MS)</option>
          <option value="Master of Arts">Master of Arts (MA)</option>
          <option value="Master of Business Administration">
            Master of Business Administration (MBA)
          </option>
          <option value="Doctor of Medicine">Doctor of Medicine (MD)</option>
          <option value="Doctor of Philosophy">Doctor of Philosophy (PhD)</option>
          <option value="Postdoctoral Studies">Postdoctoral Studies</option>
          <option value="Certificate">Certificate</option>
          <option value="Professional Certification">Professional Certification</option>
        </select>

        <select
          id="role"
          style={{
            width: "95%",
            fontSize: "14px",
            padding: "10px",
            borderRadius: "5px",
            color: "grey",
            border: "1px solid lightgrey",
            marginTop: 20,
          }}
        >
          <option value="">Role</option>
          <option value="Registral">Registral</option>
          <option value="Theacher">Theacher</option>
          <option value="Encoder">Encoder</option>
        </select>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 20,
            marginBottom: "10%",
          }}
        >
          <MDButton variant="gradient" color="info" style={{ width: "50%" }} onClick={handleSubmit}>
            Add Staff
          </MDButton>
        </div>
      </MDBox>

      <FileUpload />
    </MDBox>
  ) : (
    <MDBox display="flex" justifyContent="space-around">
      <MDBox pt={4} flex={1} mx={3}>
        <MDBox mb={2}>
          <MDInput
            id="first-name"
            label="First name *"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            onKeyPress={(event) => handleKeyPress(event, "last-name")}
            style={{ width: "45%" }}
            error={!!errors.firstName}
            helperText={errors.firstName}
          />
          <MDInput
            id="last-name"
            type="text"
            label="Last name *"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            onKeyPress={(event) => handleKeyPress(event, "parent-first-name")}
            style={{ width: "45%", marginLeft: "5%" }}
            error={!!errors.lastName}
            helperText={errors.lastName}
          />
        </MDBox>
        <MDBox>
          <MDInput
            id="parent-first-name"
            label="Parent First name *"
            value={parentFirstName}
            onChange={(e) => setParentFirstName(e.target.value)}
            onKeyPress={(event) => handleKeyPress(event, "parent-last-name")}
            style={{ width: "45%" }}
            error={!!errors.parentFirstName}
            helperText={errors.parentFirstName}
          />
          <MDInput
            id="parent-last-name"
            type="text"
            label="Parent Last name *"
            value={parentLastName}
            onChange={(e) => setParentLastName(e.target.value)}
            onKeyPress={(event) => handleKeyPress(event, "grade_level")}
            style={{ width: "45%", marginLeft: "5%" }}
            error={!!errors.parentLastName}
            helperText={errors.parentLastName}
          />
        </MDBox>
        <select
          id="grade_level"
          value={gradeLevel}
          onChange={(e) => setGradeLevel(e.target.value)}
          style={{
            width: "45%",
            fontSize: "14px",
            padding: "10px",
            borderRadius: "5px",
            color: "grey",
            border: "1px solid lightgrey",
            marginTop: 10,
          }}
        >
          <option value="">Grade *</option>
          <option value="1">1</option>
          <option value="2">2 </option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7 </option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
          <option value="11">11</option>
          <option value="12">12</option>
        </select>
        {errors.gradeLevel && <div style={{ color: "red", marginTop: 5 }}>{errors.gradeLevel}</div>}
        <select
          id="grade_section"
          value={gradeSection}
          onChange={(e) => setGradeSection(e.target.value)}
          style={{
            width: "45%",
            fontSize: "14px",
            padding: "10px",
            borderRadius: "5px",
            color: "grey",
            border: "1px solid lightgrey",
            marginTop: 20,
            marginLeft: "5%",
          }}
        >
          <option value="">Section *</option>
          <option value="a">A</option>
          <option value="b">B</option>
          <option value="c">C</option>
          <option value="d">D</option>
          <option value="e">E</option>
          <option value="f">F</option>
          <option value="g">G</option>
        </select>
        {errors.gradeSection && (
          <div style={{ color: "red", marginTop: 5 }}>{errors.gradeSection}</div>
        )}
        <MDBox mb={2} mt={2}>
          <PhoneInput
            id="phone_input"
            country={"et"}
            value={phone}
            placeholder="+ (251) 900-000-00"
            onChange={(numbers) => {
              setPhone(numbers);
            }}
            preferredCountries={["et", "ke", "dj", "sd"]}
            enableSearch={true}
            countryCodeEditable={false}
            inputStyle={{
              width: "95%",
              height: "45px",
            }}
            autocompleteSearch={true}
            onKeyPress={(event) => handleKeyPress(event, "parent-email")}
          />
          {errors.phone && <div style={{ color: "red", marginTop: 5 }}>{errors.phone}</div>}
        </MDBox>
        <MDBox>
          <MDInput
            id="parent-email"
            type="email"
            label="Parent email"
            value={parentEmail}
            onChange={(e) => setParentEmail(e.target.value)}
            onKeyPress={(event) => handleKeyPress(event, "phone_input")}
            style={{ width: "95%", marginBottom: "10px" }}
            error={!!errors.parentEmail}
            helperText={errors.parentEmail}
          />
        </MDBox>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              defaultValue={dateValue}
              views={["year", "month", "day"]}
              onChange={(date) => setDateValue(date)}
            />
          </DemoContainer>
        </LocalizationProvider>
        <FormControl style={{ marginLeft: "20%" }}>
          <RadioGroup
            aria-label="Gender"
            row
            name="row-radio-buttons-group"
            value={gender}
            onChange={(e) => setGender(e.target.value)}
          >
            <FormControlLabel value="f" control={<Radio />} label="Female" />
            <FormControlLabel value="m" control={<Radio />} label="Male" />
          </RadioGroup>
        </FormControl>
        {errors.gender && <div style={{ color: "red", marginTop: 5 }}>{errors.gender}</div>}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 20,
            marginBottom: "10%",
          }}
        >
          <MDButton
            variant="gradient"
            color="info"
            style={{ width: "50%" }}
            onClick={handleSubmitStudent}
          >
            Add Student
          </MDButton>
        </div>
      </MDBox>
      <FileUpload />
    </MDBox>
  );
};
AddingComp.propTypes = {
  isStaff: PropTypes.bool.isRequired,
};
export default AddingComp;
