import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables/teachersTable";
import Tables2 from "layouts/tables/registerTable";
import Broadcast from "layouts/billing";
import Classes from "layouts/Classes";
import Subjects from "layouts/Subjects";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import Icon from "@mui/material/Icon";
import { Podcasts } from "@mui/icons-material";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import SchoolIcon from "@mui/icons-material/School";
import ClassIcon from "@mui/icons-material/Class";
import Forgotten from "layouts/authentication/forgottenpassword";
import Attendance from "layouts/attendance/attendance";

let routes = [];
routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Staffs",
    key: "staffs",
    icon: <CastForEducationIcon />,
    route: "/staffs",
    component: <Tables routename="Teachers" />,
  },

  {
    type: "collapse",
    name: "Students",
    key: "students",
    icon: <SchoolIcon />,
    route: "/students",
    component: <Tables routename="Students" />,
  },
  {
    type: "collapse",
    name: "Classes",
    key: "classes",
    icon: <ClassIcon />,
    route: "/classes",
    component: <Classes />,
  },
  {
    type: "collapse",
    name: "Subjects",
    key: "subjects",
    icon: <ClassIcon />,
    route: "/subjects",
    component: <Subjects />,
  },
  {
    type: "collapse",
    name: "Broadcast",
    key: "broadcast",
    icon: <Podcasts />,
    route: "/broadcast",
    component: <Broadcast />,
  },
  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
  },
  {
    type: "collapse",
    name: "Attendance",
    key: "attendance",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/attendance",
    component: <Attendance />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "register",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/registerTable",
    component: <Tables2 />,
  },
  {
    type: "collapse",
    name: "reset-password",
    key: "reset-password",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/reset",
    component: <Forgotten />,
  },
];

export default routes;
