import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";

import Header from "layouts/profile/components/Header";

import { profile } from "context/profile";

function Overview() {
  console.log(profile);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} xl={6} sx={{ display: "flex" }}>
              <ProfileInfoCard
                title="profile information"
                description={`Welcome, ${profile.school_name} ${profile.role}! As the backbone of Askual Link in your school, you manage user accounts, streamline communication, monitor student progress, and empower collaboration between teachers, parents, and students. Explore the features and customize settings to optimize your school's learning environment.`}
                info={{
                  fullName: `${profile.first_name} ${profile.last_name}`,
                  mobile: profile.phone_number,
                  email: profile.email,
                  location: "Ethiopia",
                }}
                social={[
                  {
                    link: "https://www.facebook.com/askuala-link/",
                    icon: <FacebookIcon />,
                    color: "facebook",
                  },
                  {
                    link: "https://twitter.com/askuala-link",
                    icon: <TwitterIcon />,
                    color: "twitter",
                  },
                  {
                    link: "https://www.instagram.com/askuala-link",
                    icon: <InstagramIcon />,
                    color: "instagram",
                  },
                ]}
                action={{ route: "", tooltip: "Edit Profile" }}
                shadow={false}
              />
            </Grid>
          </Grid>
        </MDBox>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
