export let profile = {
  staff_id: "",
  first_name: "",
  last_name: "",
  phone_number: "",
  email: "",
  subject_id: "5a244d1d-6363-4006-9622-8cc000274bfa",
  educational_level: "",
  represent_grade: 0,
  represent_section: "",
  role: "None",
  school_name: "",
  school_id: "9ab3b9f5-8c1a-433d-a1e5-56d1b399da20",
  accessToken: "",
  refreshToken: "",
};

export let dashboard = {
  totalstud: 0,
};

export let studentList = [];
