import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import DefaultNavbarLink from "examples/Navbars/DefaultNavbar/DefaultNavbarLink";

import authorsTableData from "layouts/tables/data/authorsTableData";
import { LastPage } from "@mui/icons-material";

function Tables2() {
  const { columns, rows } = authorsTableData();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container Spacing={1} display={"flex"}>
                  <Grid item xs={12} sm={6} lg={7}>
                    <MDTypography variant="h6" color="white">
                      registerals Table
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={1}>
                    <DefaultNavbarLink
                      icon="plus"
                      name="plus"
                      route="/authentication/sign-in"
                      // light={light}
                    />
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables2;
