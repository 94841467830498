import axiosInstance from "config/axios-config.js";

export const postattendance = async (data) => {
  let response = {
    message: "",
    success: "",
  };
  try {
    const result = await axiosInstance.post("/attendance", {
      date: data.date,
      time: data.time,
      student_id: data.student_id,
      staff_id: data.staff_id,
      type: data.type,
    });
    response.success = result.data.success;
    response.message = result.data.message;
  } catch (error) {
    response.success = false;
    response.message = "Attendance report failed";
    console.error("Error submitting data:", error);
  }
  return response;
};
