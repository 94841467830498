import { Outlet, Navigate, Route, useLocation } from "react-router-dom";
import { profile } from "../context/profile.js";
import { getToken, removeToken } from "../config/storageController.js";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { LoadinSpiner } from "../components/LoadingSpinner";
import { isAuthenticated } from "context/isAuthenticated.js";
import Backdrop from "@mui/material/Backdrop";

const PrivateRoute = (element) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticatedState, setIsAuthenticatedState] = useState(false);

  useEffect(() => {
    const checkAuthentication = async () => {
      const authenticated = await isAuthenticated();
      setIsAuthenticatedState(authenticated);
      setIsLoading(false);
    };

    checkAuthentication();
  }, []);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  if (isLoading) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        onClick={handleClose}
      >
        <LoadinSpiner />
      </Backdrop>
    );
  }
  return isAuthenticatedState && <Outlet />;
};

export default PrivateRoute;
