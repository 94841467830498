import React, { useState, useEffect } from "react";
import { Select, MenuItem, FormControl, InputLabel, Typography, Box } from "@mui/material";
import StudentField from "../../components/StudentGrade";
import MDBox from "components/MDBox";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import { FileUpload } from "components/FileUpload";
import { getStudents } from "utils/requests/students/studentsget";
import MDButton from "components/MDButton";
import { profile } from "context/profile";
import { postresult } from "utils/requests/result/resultpost";

export default function StudentAdd() {
  const [examType, setExamType] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [studentslist, setStudentsList] = useState([]);
  const [marks, setMarks] = useState({}); // New state to store marks
  const studentsPerPage = 10;

  // Calculate total pages
  const totalPages = Math.ceil(studentslist.length / studentsPerPage);

  // Calculate students to display on the current page
  const indexOfLastStudent = currentPage * studentsPerPage;
  const indexOfFirstStudent = indexOfLastStudent - studentsPerPage;
  const currentStudents = studentslist.slice(indexOfFirstStudent, indexOfLastStudent);

  const handleExamTypeChange = (event) => {
    setExamType(event.target.value);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Fetch students list
  useEffect(() => {
    const fetchStudents = async () => {
      let students = await getStudents();
      setStudentsList(students.data.data);
    };
    fetchStudents();
  }, []);

  // Function to handle the mark change for a student
  const handleMarkChange = (index, value) => {
    setMarks((prevMarks) => ({
      ...prevMarks,
      [index]: value, // Update mark for the student at the given index
    }));
  };

  const handleSubmit = async () => {
    const newResults = currentStudents.map((student, index) => {
      // Log student data for verification
      console.log(student);

      // Construct result object for each student
      const result = {
        // result_id: `731b156a-4c88-4a9a-afda-15affa01a7d1`, // Generate result ID
        exam_id: "731b156a-4c88-4a9a-afda-15affa01a7d1", // From form input (exam type)
        mark: marks[index] || "N/A", // Use entered mark or default to "N/A"
        subject_id: profile.subject_id, // Update with actual subject ID
        school_id: student.school_id, // From student object
        student_id: student.Student_id, // From student object
        mark_rank: "Unranked", // Placeholder, adjust if needed
        comment: "Good job", // Add a comment (can be dynamic)
      };

      return result; // Return the result object for each student
    });

    // Loop through the results array and submit each result to the backend
    try {
      for (const result of newResults) {
        postresult(result); // Use the postresult function for each result
      }
      console.log("All results submitted successfully!");
      console.log(newResults);
    } catch (error) {
      console.error("Error submitting results:", error);
    }
  };
  return (
    <MDBox pl={10} pt={5} bgcolor="lightgreen" borderRadius="16px" boxShadow={3}>
      <Box display="flex">
        <Box flex="1" mr={4}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel
                  id="exam-type-label"
                  sx={{ paddingTop: "8px", paddingBottom: "8px", fontSize: "15px" }}
                >
                  Exam Type
                </InputLabel>
                <Select
                  labelId="exam-type-label"
                  id="exam-type"
                  value={examType}
                  onChange={handleExamTypeChange}
                  label="Exam Type"
                  sx={{ height: "56px", display: "flex", alignItems: "center" }}
                >
                  <MenuItem value="Midterm">Midterm</MenuItem>
                  <MenuItem value="Final">Final</MenuItem>
                  <MenuItem value="Quiz">Quiz</MenuItem>
                  <MenuItem value="Assignment">Assignment</MenuItem>
                </Select>
              </FormControl>

              <Typography variant="h6" gutterBottom>
                Students
              </Typography>

              {currentStudents.map((student, index) => (
                <StudentField
                  key={index}
                  index={index}
                  student={student}
                  sizes={currentStudents.length}
                  handleMarkChange={handleMarkChange} // Pass function to handle mark changes
                />
              ))}

              <MDButton
                onClick={handleSubmit} // Trigger submit action
                variant="gradient"
                color="info"
                fullWidth
              >
                Submit
              </MDButton>
            </Grid>

            <Grid item xs={6}>
              <FileUpload />
            </Grid>
          </Grid>

          <Box mt={4} display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="body2">
              Showing {indexOfFirstStudent + 1} to{" "}
              {Math.min(indexOfLastStudent, studentslist.length)} of {studentslist.length} Students
            </Typography>
            <Box display="flex" alignItems="center">
              <ChevronLeft
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
                sx={{
                  cursor: currentPage === 1 ? "not-allowed" : "pointer",
                  opacity: currentPage === 1 ? 0.5 : 1,
                }}
              />
              <Typography variant="body2" mx={2}>
                Page {currentPage} of {totalPages}
              </Typography>
              <ChevronRight
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                sx={{
                  cursor: currentPage === totalPages ? "not-allowed" : "pointer",
                  opacity: currentPage === totalPages ? 0.5 : 1,
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </MDBox>
  );
}
