import Logo from "../assets/images/askuala primary.png";
import { FaTwitter, FaFacebookF, FaLinkedinIn, FaInstagram } from "react-icons/fa";

export default () => {
  const footerNavs = [
    { href: "https://askuala.com/about", name: "About" },
    // { href: "https://askuala.com/blog", name: "Blog" }, we will comming soon
    { href: "https://askuala.com/products", name: "Products" },
    { href: "https://askuala.com/team", name: "Team" },
    { href: "https://askuala.com/careers", name: "Careers" },
    { href: "https://askuala.com/support", name: "Support" },
  ];

  return (
    <footer className="text-gray-500 bg-gray-100 px-8 py-8 w-full">
      <div className="flex flex-col sm:flex-row justify-between max-w-screen-xl mx-auto space-y-6 sm:space-y-0">
        <div className="w-full sm:w-1/4">
          <img src={Logo} className="w-24" alt="Askuala Logo" />
          <p className="leading-relaxed mt-2 text-[13px] sm:text-[14px] font-light">
            Enhancing education with innovation.
          </p>
        </div>

        <ul className="flex flex-wrap space-x-4 w-full sm:w-1/2 justify-center sm:justify-center text-[13px] sm:text-[14px]">
          {footerNavs.map((item, idx) => (
            <li
              key={idx}
              className="hover:text-gray-800 font-medium transition-all duration-300 hover:underline"
            >
              <a href={item.href}>{item.name}</a>
            </li>
          ))}
        </ul>

        <ul className="w-full sm:w-1/4 flex justify-center sm:justify-end space-x-4">
          {[
            { href: "https://twitter.com/askuala", icon: <FaTwitter />, color: "text-blue-400" },
            { href: "https://facebook.com/askuala", icon: <FaFacebookF />, color: "text-blue-700" },
            {
              href: "https://linkedin.com/company/askuala",
              icon: <FaLinkedinIn />,
              color: "text-blue-500",
            },
            {
              href: "https://instagram.com/askuala",
              icon: <FaInstagram />,
              color: "text-pink-500",
            },
          ].map((link, idx) => (
            <li
              key={idx}
              className="relative w-8 h-8 rounded-full border-2 border-gray-400 flex items-center justify-center group hover:border-transparent hover:bg-gray-200 transition-all duration-500"
            >
              <a
                href={link.href}
                className={`group-hover:${link.color} transition-transform duration-300 group-hover:scale-110`}
              >
                {link.icon}
              </a>
            </li>
          ))}
        </ul>
      </div>

      {/* Adding cool style to email address */}
      <div className="mt-6 text-center text-[14px] sm:text-[15px]">
        <p className="inline-block bg-gradient-to-r from-blue-400 to-teal-400 text-transparent bg-clip-text font-semibold animate-pulse">
          Contact us:{" "}
        </p>
        <a
          href="mailto:info@askualalink.com"
          className="hover:underline text-blue-500 font-bold transition-colors duration-300 hover:text-teal-400"
        >
          info@askualalink.com
        </a>
      </div>

      <div className="mt-4 text-center text-[12px] text-gray-600">
        &copy; 2024 Askuala Link. All rights reserved.
      </div>
    </footer>
  );
};
