import { axiosInstanceAuth } from "config/axios-config";
import { setStudents } from "layouts/billing/components/StudentList/ListOfStudent/Listofstudents";
export const getStudents = async () => {
  let axiosAuth = axiosInstanceAuth().axiosInstance;
  let response = await axiosAuth.get("/student").catch((error) => {
    console.log(error);
  });
  return response;
};

export const createStudents = async (obj) => {
  let axiosAuth = axiosInstanceAuth().axiosInstance;
  console.log(obj);
  let response = await axiosAuth.post("/parent", obj).catch((error) => {
    console.log(error);
    return { success: false, error: "Can't create students" };
  });
  console.log(response);
  return response;
};
