import Card from "@mui/material/Card";
import { Avatar, Pagination } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ListComponent from "../StudentList";
import { FilterAlt } from "@mui/icons-material";
import List from "../List/list";
import { getStudents } from "utils/requests/students/studentsget";

function BoadCastList(props) {
  const [click, setClick] = useState(false);
  const { handler } = props;
  const [selectedItems, setSelectedItems] = useState([]);
  const [fetched, setFetched] = useState([]);
  const [specific, setSpacific] = useState("");
  const [gradeLevel, setGradeLevel] = useState("");
  const [errors, setErrors] = useState({});
  const [gradeSection, setGradeSection] = useState("");
  const [isSpecificClass, setIsSpecificClass] = useState(false);
  const [clickItems, setClickItems] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const studentsPerPage = 4; // Set number of students per page to 5

  const handleClick = (index, student) => {
    if (selectedItems.includes(index)) {
      setSelectedItems(selectedItems.filter((item) => item !== index));
      handler(student.Student_id, false);
    } else {
      handler(student.Student_id, true);
      setSelectedItems([...selectedItems, index]);
    }
  };

  const clickhandler = () => {
    setClick(!click);
    setIsSpecificClass(false);
  };

  const allSelected = () => {
    console.log("all selected function running");
    console.log(fetched);
    fetched.map((item, index) => {
      selectedItems.push(index);
    });
  };

  const menuSelected = (value) => {
    setClick(false); // Reset click on menu selection
  };

  useEffect(() => {
    const fetch = async () => {
      let students = await getStudents();
      setFetched(students.data.data);
    };
    fetch();
  }, []);

  const checkISelected = (index) => {
    handler(index);
    return selectedItems.includes(index);
  };

  const specificclickhandler = (booleane) => {
    setSpacific(booleane);
  };

  const specificClassUI = () => {
    setIsSpecificClass(true);
  };

  const handleSelectionChange = (event) => {
    const { id, value } = event.target;
    setClickItems((prevItems) => ({ ...prevItems, [id]: value }));
  };

  const handleClickSubmit = () => {
    setGradeLevel("");
    setGradeSection("");
  };

  const list_clear = () => {
    setSelectedItems([]);
  };

  // Pagination handlers
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Calculate the current students to display
  const indexOfLastStudent = currentPage * studentsPerPage;
  const indexOfFirstStudent = indexOfLastStudent - studentsPerPage;
  const currentStudents = fetched.slice(indexOfFirstStudent, indexOfLastStudent);

  return (
    <Card
      sx={{
        height: "400px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography variant="h6" fontWeight="medium">
          Choose receiver
        </MDTypography>
        <MDButton variant="outlined" color="info" size="small" onClick={clickhandler}>
          view all &nbsp;
          <FilterAlt style={{ fontWeight: "bold" }} />
        </MDButton>
      </MDBox>
      <MDBox p={2} flex="1" display="flex" flexDirection="column" justifyContent="space-between">
        {!click && !isSpecificClass && (
          <>
            <MDBox
              component="ul"
              display="flex"
              flexDirection="column"
              p={0}
              mt={0}
              sx={{ flexGrow: 1 }}
            >
              {currentStudents.map((student, index) => (
                <button
                  onClick={() => handleClick(indexOfFirstStudent + index, student)}
                  key={index}
                  style={{
                    border: "none",
                    backgroundColor: selectedItems.includes(indexOfFirstStudent + index)
                      ? "#f0f2f5"
                      : "transparent",
                    outline: "none",
                    boxShadow: "none",
                    marginTop: 4,
                  }}
                >
                  <ListComponent
                    name={student.first_name}
                    grade={student.grade}
                    selected={checkISelected(indexOfFirstStudent + index)}
                  />
                </button>
              ))}
            </MDBox>
            {/* Pagination inside the card, positioned at the bottom */}
            <MDBox sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
              <Pagination
                count={Math.ceil(fetched.length / studentsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
              />
            </MDBox>
          </>
        )}
        {click && !isSpecificClass && (
          <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
            <List
              updateSelectedItems={handler}
              uiUpdater={allSelected}
              menuSelected={menuSelected}
              handler={specificclickhandler}
              specificClassUI={specificClassUI}
              clear={list_clear}
            />
          </MDBox>
        )}
        {!click && isSpecificClass && (
          <MDBox>
            <MDBox component="ul" display="flex" flexDirection="row" p={0} m={0}>
              <select
                id="grade_level"
                value={gradeLevel}
                onChange={(e) => {
                  setGradeLevel(e.target.value);
                  handleSelectionChange(e);
                }}
                style={{
                  width: "45%",
                  fontSize: "14px",
                  padding: "10px",
                  borderRadius: "5px",
                  color: "grey",
                  border: "1px solid lightgrey",
                  marginTop: 20,
                  marginLeft: "5%",
                }}
              >
                <option value="">Grade *</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </select>
              {errors.gradeLevel && (
                <div style={{ color: "red", marginTop: 5 }}>{errors.gradeLevel}</div>
              )}
              <select
                id="grade_section"
                value={gradeSection}
                onChange={(e) => {
                  setGradeSection(e.target.value);
                  handleSelectionChange(e);
                }}
                style={{
                  width: "45%",
                  fontSize: "14px",
                  padding: "10px",
                  borderRadius: "5px",
                  color: "grey",
                  border: "1px solid lightgrey",
                  marginTop: 20,
                  marginLeft: "5%",
                }}
              >
                <option value="">Section *</option>
                <option value="a">A</option>
                <option value="b">B</option>
                <option value="c">C</option>
                <option value="d">D</option>
                <option value="e">E</option>
                <option value="f">F</option>
                <option value="g">G</option>
              </select>
              {errors.gradeSection && (
                <div style={{ color: "red", marginTop: 5 }}>{errors.gradeSection}</div>
              )}
            </MDBox>
            <div className="mt-6 flex justify-center gap-x-2">
              <button
                onClick={handleClickSubmit}
                type="submit"
                className="flex-none rounded-md bg-blue-500 w-2/5 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              >
                submit
              </button>
            </div>
          </MDBox>
        )}
      </MDBox>
    </Card>
  );
}

BoadCastList.propTypes = {
  handler: PropTypes.func.isRequired,
};

export default BoadCastList;
