// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyB28zekHRbxD84N9GX2jR6BGqWTnzw4JGk",
  authDomain: "askuala-link.firebaseapp.com",
  projectId: "askuala-link",
  storageBucket: "askuala-link.appspot.com",
  messagingSenderId: "52863161249",
  appId: "1:52863161249:web:c52fc77a92f84d2014b1e0",
  measurementId: "G-W4213LHXSG",
};

const app = initializeApp(firebaseConfig);
export const fileDB = getStorage(app);
