import axios from "axios";
import { getToken } from "./storageController.js";
import { profile } from "context/profile.js";
import { isAuthenticated } from "context/isAuthenticated.js";

// Create an instance of Axios
export const axiosInstance = axios.create({
  baseURL: "http://localhost:5000/api/v1/",
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosUploadInstance = axios.create({
  baseURL: "http://localhost:5000/api/v1/",
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export const axiosInstanceAuth = () => {
  console.log(localStorage.getItem("reset-token"));
  if (isAuthenticated()) {
    let token = getToken();
    if (token === null || token === undefined) token = profile.accessToken;
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axiosUploadInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return { axiosInstance, axiosUploadInstance };
  } else {
    console.log("user Is not Authenticated");
  }
};

export default axiosInstance;
