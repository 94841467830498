import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import CEO from "../assets/images/ceo.jpg";
import CTO from "../assets/images/cto.jpg";

export default () => {
  const team = [
    {
      avatar: CEO,
      name: "Fanuel Almaw",
      title: "CEO",
      desc: "With a passion for innovation in education, Fanuel Almaw leads our team as CEO. His extensive experience in the industry drives our mission to deliver top-notch educational solutions.",
      linkedin: "javascript:void(0)",
      twitter: "javascript:void(0)",
    },
    {
      avatar: CTO,
      name: "Kidist Mamaye",
      title: "CTO",
      desc: "Kidist Mamaye, our CTO, brings cutting-edge technology expertise to the table. Her dedication to leveraging advanced tech for educational purposes ensures our platform remains at the forefront of the industry.",
      linkedin: "javascript:void(0)",
      twitter: "javascript:void(0)",
    },
  ];

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.3 } // Trigger animation when 30% of the section is visible
    );

    const section = document.getElementById("team-section");
    if (section) {
      observer.observe(section);
    }

    return () => {
      if (section) observer.unobserve(section);
    };
  }, []);

  return (
    <section id="team-section" className="pt-24 pb-14 flex justify-center">
      <div className="max-w-screen-xl px-4 text-center md:px-8">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={isVisible ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.8, ease: [0.42, 0, 0.58, 1] }}
          className="max-w-xl mx-auto"
        >
          <h3 className="text-gray-800 text-3xl font-semibold sm:text-4xl">Meet our team</h3>
        </motion.div>

        <div className="mt-12">
          <motion.ul
            initial="hidden"
            animate={isVisible ? "visible" : "hidden"}
            variants={{
              visible: {
                opacity: 1,
                transition: {
                  staggerChildren: 0.2, // Stagger the appearance of each team member
                  delayChildren: 0.3, // Delay before starting the animation
                },
              },
              hidden: { opacity: 0 },
            }}
            className="grid gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 justify-center"
          >
            {team.map((item, idx) => (
              <motion.li
                key={idx}
                className="flex flex-col items-center bg-white p-6 shadow-lg rounded-lg"
                variants={{
                  visible: {
                    opacity: 1,
                    y: 0,
                    scale: 1,
                    transition: {
                      duration: 0.6,
                      ease: [0.42, 0, 0.58, 1],
                    },
                  },
                  hidden: { opacity: 0, y: 50, scale: 0.9 },
                }}
              >
                <div className="w-24 h-24 mx-auto">
                  <img src={item.avatar} className="w-full h-full rounded-full" alt="" />
                </div>
                <div className="mt-2 text-center">
                  <h4 className="text-gray-700 font-semibold sm:text-lg">{item.name}</h4>
                  <p className="text-indigo-600">{item.title}</p>
                  <p className="text-gray-600 mt-2">{item.desc}</p>
                  <div className="mt-4 flex justify-center gap-4 text-gray-400">
                    <a href={item.twitter}>
                      <svg
                        className="w-5 h-5 duration-150 hover:text-gray-500"
                        fill="currentColor"
                        viewBox="0 0 48 48"
                      >
                        {/* Twitter icon */}
                      </svg>
                    </a>
                    <a href={item.linkedin}>
                      <svg
                        className="w-5 h-5 duration-150 hover:text-gray-500"
                        fill="none"
                        viewBox="0 0 48 48"
                      >
                        {/* LinkedIn icon */}
                      </svg>
                    </a>
                  </div>
                </div>
              </motion.li>
            ))}
          </motion.ul>
        </div>
      </div>
    </section>
  );
};
