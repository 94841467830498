import axiosInstance from "config/axios-config";
import { profile } from "context/profile";
import { Outlet, useNavigate } from "react-router-dom";
import { useState } from "react";
import { LoadinSpiner } from "components/LoadingSpinner";
export const verifyOtp = async (phone, otp) => {
  let response;
  try {
    response = await axiosInstance
      .post("/account/verify", {
        phone_number: phone,
        otp: otp,
      })
      .then((res) => {
        if (res.data.success) {
          localStorage.setItem("reset-token", res.data.data);
          let res_token = localStorage.getItem("reset-token");
          console.log(res_token);
          return {
            data: res.data.data,
            success: res.data.success,
            message: res.data.message,
          };
        } else {
          return {
            data: null,
            success: res.data.success,
            message: res.data.message,
          };
        }
      })
      .catch((error) => {
        console.log(error);
        return {
          data: null,
          success: false,
          message: error.response.data.message,
        };
      });
    return response;
  } catch (error) {
    return {
      data: null,
      success: false,
      message: error.response.data.message,
    };
  }
};

export const forgot_password = async (phone) => {
  try {
    const response = await axiosInstance
      .post("/account/forgot", { phone_number: phone })
      .catch((error) => {
        console.log(error.response.data);
        return {
          message: error.response.data.message,
          success: error.response.data.success,
        };
      });
    if (response.data) {
      return response.data;
    }
    return response;
  } catch (error) {
    return {
      message: error.response,
      success: error.response,
    };
  }
};
