import { Avatar, Typography } from "@mui/material";
import AvatarColor from "components/ColorAvater/ColorAvater";
import MDBox from "components/MDBox";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import PropTypes from "prop-types";
export default function StudentField({ student, index, sizes, handleMarkChange }) {
  const [ids, setIds] = useState(index);
  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      let targetId;
      if (ids < sizes - 1) {
        targetId = ids + 1;
      } else {
        targetId = ids;
      }
      const nextField = document.getElementById(targetId);
      if (nextField) nextField.focus();
    }
  };

  return (
    <MDBox
      display="flex"
      alignItems="center"
      p={2}
      bgcolor="#f5f5f5"
      borderRadius={1}
      boxShadow={1}
    >
      <Avatar sx={{ bgcolor: AvatarColor(student.first_name), marginRight: 2 }}>
        {student.first_name.charAt(0)}
      </Avatar>
      <div style={{ flexGrow: 1 }}>
        <Typography variant="body1" color="textPrimary" sx={{ fontSize: "0.9rem" }}>
          {student.first_name} {student.middle_name}
        </Typography>
      </div>
      <TextField
        id={ids.toString()} // Use unique ID per student
        label="Grade"
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        onKeyDown={handleEnterPress}
        onChange={(e) => handleMarkChange(index, e.target.value)} // Capture mark input
      />
    </MDBox>
  );
}
StudentField.propTypes = {
  student: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    middle_name: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  sizes: PropTypes.number.isRequired,
  handleMarkChange: PropTypes.func.isRequired,
};
