import axiosInstance from "config/axios-config";
import { profile } from "context/profile";
import { setToken } from "../../config/storageController";
import { Outlet, useNavigate } from "react-router-dom";
import { useState } from "react";
import { LoadinSpiner } from "components/LoadingSpinner";
export const userLogin = async (userName, userPassword) => {
  let response;
  try {
    response = await axiosInstance
      .post("/account/login", {
        email: userName,
        password: userPassword,
      })
      .then((res) => {
        console.log(res.data.data);
        profileDataMapper(res.data.data);
        setToken();
        if (res.data.success) return true;
        else return false;
      })
      .catch((error) => {
        console.log(error.response);
        response = error.response.data.success;

        return false;
      });
  } catch (error) {
    console.log(error);
    return false;
  }

  return response;
};

export const profileDataMapper = (data) => {
  profile.staff_id = data.staff_id;
  profile.subject_id = data.subject_id;
  profile.school_name = data.school_name;
  profile.role = data.role;
  profile.represent_section = data.represent_section;
  profile.represent_grade = data.represent_grade;
  profile.refreshToken = data.refreshToken;
  profile.phone_number = data.phone_number;
  profile.last_name = data.last_name;
  profile.first_name = data.first_name;
  profile.email = data.email;
  profile.educational_level = data.educational_level;
  profile.accessToken = data.accessToken;
};
