import { fileDB } from "firebaseupload/config";
import MDBox from "components/MDBox";
import { useState } from "react";
import MDButton from "components/MDButton";
import { LoadinSpiner } from "components/LoadingSpinner";
import Lottie from "lottie-react";
import checkMark from "assets/lottie/Animation - 1709580077501.json";
import SelectFile from "assets/lottie/Animation - 1709585629758.json";
import CheckingFile from "assets/lottie/Animation - 1709585724092.json";
import { axiosInstanceAuth } from "config/axios-config";
import "../../index.css";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { snackbar } from "components/awesome_snackbar/snackbar";
import axiosInstance from "config/axios-config";
import { axiosUploadInstance } from "config/axios-config";
export const FileUpload = () => {
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState();
  const [validating, setValidating] = useState(false);
  const headers = [
    "first_name",
    "last_name",
    "phone_number",
    "email",
    "subject",
    "educational_level",
    "represent_grade",
    "represent_section",
    "role",
  ];

  // Function to generate the Excel template for download
  const generateTemplate = () => {
    const ws = XLSX.utils.aoa_to_sheet([headers]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Staff Data");

    // Export to file
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(data, "staff_template.xlsx");
  };

  const validateExcelFile = (data) => {
    const sheet = data[0];
    const headersFromSheet = sheet.slice(0, headers.length);

    for (let i = 0; i < headers.length; i++) {
      if (headersFromSheet[i] !== headers[i]) {
        throw new Error(`Invalid Excel format: Expected ${headers[i]}, got ${headersFromSheet[i]}`);
      }
    }

    for (let i = 1; i < data.length; i++) {
      const row = data[i];
      for (let j = 0; j < headers.length; j++) {
        let column = row[j];
        console.log(column);
        console.log(j);
        if (
          (j === 6 && column[j] === null) ||
          column[j] === undefined ||
          (j == 5 && column[j] === null) ||
          column[j] === undefined
        ) {
          continue;
          // if (row[7] == "teacher") {
          //ask for additional info first please
          // }
        }
        if (column == "" || column == null || column == undefined)
          throw Error(`Empty column on row ${i} in ${headers[j]}, please fix the error first`);
      }
    }

    for (let i = 1; i < data.length; i++) {
      const phoneNumber = data[i][2];
      if (!/^[\d]+$/.test(phoneNumber)) {
        throw new Error(`Invalid phone number on row ${i + 1}`);
      }
    }

    return true;
  };

  const handleUploadClick = async () => {
    if (!file) {
      snackbar("error", "Please upload a file first");
      return;
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
        header: 1,
      });
      let axiosAuth = axiosInstanceAuth().axiosUploadInstance;
      try {
        setValidating(true);
        validateExcelFile(worksheet);
        setValidating(false);
        const formData = new FormData();
        formData.append("file", file);

        setIsUploading(true);
        const response = await axiosAuth.post("/staff/upload", formData);
        setUploadSuccess(true);
        snackbar("success", "XL Registration Successful");
        console.log("Upload successful:", response.data);
      } catch (error) {
        snackbar("error", error.message);
      } finally {
        setIsUploading(false);
      }
    };

    reader.readAsBinaryString(file);
  };
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <MDBox pt={4} flex={1} mx={3} my={10}>
      <div className="border-dashed border border-gray-400 rounded-md ">
        <label htmlFor="file-upload" className="cursor-pointer inset-0">
          <input
            type="file"
            id="file-upload"
            className="hidden"
            onChange={handleFileChange}
            accept=".xlsx,.xls,.csv"
            tabIndex={-1}
            style={{
              width: 0,
              height: 0,
              overflow: "hidden",
              position: "absolute",
              zIndex: -1,
            }}
          />
          {file !== null ? (
            <Lottie animationData={CheckingFile} loop={false} style={{ height: 100 }} />
          ) : (
            <Lottie animationData={SelectFile} loop={false} style={{ height: 100 }} />
          )}
          <p className="mt-3 text-gray-300 max-w-xs mx-auto">
            {isUploading ? "Uploading..." : "Click or Drag and Drop to Upload"}
          </p>
        </label>
      </div>

      {isUploading ? (
        <LoadinSpiner />
      ) : uploadSuccess ? (
        <Lottie animationData={checkMark} loop={false} style={{ height: 100 }} />
      ) : (
        <MDBox p={2} display="flex" gap={2}>
          {/* Download Template Button */}
          <MDButton
            onClick={generateTemplate}
            component="a"
            target="_blank"
            rel="noreferrer"
            variant="gradient"
            fullWidth
            color="primary"
          >
            Download Template
          </MDButton>

          {/* Upload Button */}
          <MDButton
            onClick={handleUploadClick}
            component="a"
            target="_blank"
            rel="noreferrer"
            variant="gradient"
            fullWidth
            color="light"
          >
            Upload
          </MDButton>
        </MDBox>
      )}
    </MDBox>
  );
};
