import MDBox from "components/MDBox";
import Alert from "@mui/material/Alert";
import MDButton from "components/MDButton";
import PropTypes from "prop-types";
import AlertTitle from "@mui/material/AlertTitle";
import MDTypography from "components/MDTypography";
import { updateNotification } from "utils/requests/notification/notification";
import Snackbar from "awesome-snackbar";
import { formatDistanceToNow } from "date-fns";

function NotificationCard(props) {
  const request_id = props.request_id;
  const message = props.message;
  const name = props.name;
  const lname = props.lname;
  const date = formatDistanceToNow(props.date);
  const role = props.role;
  const handleClick = async (requestId, status) => {
    const response = await updateNotification(requestId, status);
    console.log(response);
    if (status === "accepted" && response.success) {
      new Snackbar(`Request approved successfuly!`, {
        theme: "light",
        position: "top-center",
        style: {
          container: [
            ["background-color", "green"],
            ["color", "white"],
          ],
        },
      });
    } else if (status === "declined" && response.success) {
      new Snackbar(`Request declined successfuly!`, {
        theme: "light",
        position: "top-center",
        style: {
          container: [
            ["background-color", "red"],
            ["color", "white"],
          ],
        },
      });
    } else {
      new Snackbar(`Request Failed!`, {
        theme: "light",
        position: "top-center",
        style: {
          container: [
            ["background-color", "red"],
            ["color", "white"],
          ],
        },
      });
    }
  };
  return (
    <Alert sx={{ bgcolor: "#F0F2F5", marginBottom: "10px", borderRadius: "20px" }} icon={false}>
      <AlertTitle style={{ display: "flex" }}>
        <div style={{ position: "relative" }}>
          <span display="flex" style={{ fontSize: "16px" }}>
            {name}&nbsp;
            {lname}&nbsp;
            <span style={{ fontSize: "14px", color: "#C7C7C7" }}>/&nbsp;{role}</span>
          </span>
        </div>
        <MDTypography
          color="secondary"
          style={{ position: "absolute", right: "0", marginRight: "7%", fontSize: "12px" }}
        >
          {date}
        </MDTypography>
      </AlertTitle>
      <p style={{ marginTop: "-10px", fontSize: "12px" }}>{message}</p>
      <MDBox display="flex">
        <MDButton
          variant="gradient"
          color="success"
          onClick={() => handleClick(request_id, "accepted")}
        >
          Approve
        </MDButton>

        <MDButton
          variant="gradient"
          color="error"
          style={{ marginLeft: "20px" }}
          onClick={() => handleClick(request_id, "declined")}
        >
          decline
        </MDButton>
      </MDBox>
    </Alert>
  );
}
NotificationCard.propTypes = {
  message: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  lname: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  request_id: PropTypes.string.isRequired,
};
export default NotificationCard;
