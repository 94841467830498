import axiosInstance from "config/axios-config";
import { axiosInstanceAuth } from "config/axios-config";

export const fetchDashboard = async (setter) => {
  let axiosAuth = axiosInstanceAuth().axiosInstance;
  try {
    const response = await axiosAuth.get("/school/dashboard").catch((error) => {
      console.log(error);
    });
    setter(response.data);
  } catch (error) {
    console.log(error);
  }
};
