import axiosInstance from "config/axios-config.js";
import Teachers from "layouts/tables/data/teachersData";
import { setTeachers } from "layouts/tables/data/teachersData";
import { axiosInstanceAuth } from "config/axios-config";
export const getTeachers = async () => {
  let axiosAuth = axiosInstanceAuth().axiosInstance;
  //param pass
  let response = await axiosAuth.get("/staff").catch((error) => {
    console.log(error);
  });

  setTeachers(response.data.data);
  console.log(response.data.data);
  return response;
};
