import React, { useState, useRef, useEffect } from "react";
import { Card, CardContent, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { snackbar } from "components/awesome_snackbar/snackbar";
import AddIcon from "@mui/icons-material/Add";
import { creatSubject } from "../../utils/requests/subjects/createsubject"; // Import your function

function Subjects() {
  const [subjectList, setSubjectList] = useState([
    { subject: "", startGrade: "", endGrade: "", description: "" },
  ]);

  const refs = useRef({});

  const handleKeyDown = (e, nextRefName, index) => {
    if (e.key === "Enter" && refs.current[nextRefName + index]) {
      refs.current[nextRefName + index].focus();
    }
  };

  const handleInputChange = (index, field, value) => {
    const updatedSubjects = [...subjectList];
    updatedSubjects[index][field] = value;
    setSubjectList(updatedSubjects);
  };

  const handleSubmit = async () => {
    let allSuccess = true; // Track if all submissions succeed

    // Validate each subject form
    for (let i = 0; i < subjectList.length; i++) {
      const { subject, startGrade, endGrade } = subjectList[i];
      if (subject.trim() === "") {
        snackbar("error", "Subject Name cannot be empty!");
        refs.current[`subject${i}`].focus();
        return;
      }
      if (startGrade.trim() === "") {
        snackbar("error", "First Class cannot be empty!");
        refs.current[`startGrade${i}`].focus();
        return;
      }
      if (endGrade && parseInt(endGrade) < parseInt(startGrade)) {
        snackbar("error", "End Class cannot be less than First Class!");
        refs.current[`endGrade${i}`].focus();
        return;
      }
    }

    // Submit each subject to the backend
    for (const subject of subjectList) {
      const subjectData = {
        name: subject.subject,
        description: subject.description,
        grade_start: subject.startGrade,
        grade_end: subject.endGrade,
      };

      try {
        const response = await creatSubject(subjectData); // <-- Submitting each subject
        if (!response.success) {
          snackbar("error", `Failed to create subject '${subject.subject}': ${response.message}`);
          allSuccess = false; // Mark that submission failed
        }
      } catch (error) {
        snackbar("error", `Error submitting subject '${subject.subject}'`);
        allSuccess = false; // Mark that submission failed
      }
    }

    // Display one success message if all subjects were submitted successfully
    if (allSuccess) {
      snackbar("success", "All subjects created successfully!");
    }

    // Optionally reset the form after submission
    if (allSuccess) {
      setSubjectList([{ subject: "", startGrade: "", endGrade: "", description: "" }]);
    }
  };

  const handleAddSubject = () => {
    setSubjectList([
      ...subjectList,
      { subject: "", startGrade: "", endGrade: "", description: "" },
    ]);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card sx={{ height: "auto", width: "100%" }}>
        <CardContent>
          <MDBox
            pt={2}
            px={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <MDTypography variant="h6" fontWeight="medium">
              Create Subjects
            </MDTypography>
            <IconButton color="info" onClick={handleAddSubject}>
              <AddIcon />
            </IconButton>
          </MDBox>

          {/* Loop through each subject form */}
          {subjectList.map((subject, index) => (
            <React.Fragment key={index}>
              <MDBox display="flex" justifyContent="space-between" gap={2} px={2} mt={2}>
                <MDInput
                  fullWidth
                  label="name"
                  placeholder="Subject Name"
                  inputRef={(el) => (refs.current[`subject${index}`] = el)}
                  value={subject.subject}
                  onChange={(e) => handleInputChange(index, "subject", e.target.value)}
                  onKeyDown={(e) => handleKeyDown(e, "startGrade", index)}
                  InputLabelProps={{ shrink: true }}
                  required
                />
                <MDInput
                  fullWidth
                  label="start"
                  placeholder="Enter the first grade (e.g., 1, 2, 3)"
                  type="number"
                  inputRef={(el) => (refs.current[`startGrade${index}`] = el)}
                  value={subject.startGrade}
                  onChange={(e) => handleInputChange(index, "startGrade", e.target.value)}
                  onKeyDown={(e) => handleKeyDown(e, "endGrade", index)}
                  InputLabelProps={{ shrink: true }}
                  required
                />
                <MDInput
                  fullWidth
                  label="end"
                  placeholder="Enter the last grade (e.g., 1, 2, 3)"
                  type="number"
                  inputRef={(el) => (refs.current[`endGrade${index}`] = el)}
                  value={subject.endGrade}
                  onChange={(e) => handleInputChange(index, "endGrade", e.target.value)}
                  onKeyDown={(e) => handleKeyDown(e, "description", index)}
                  InputLabelProps={{ shrink: true }}
                />
              </MDBox>

              <MDBox display="flex" justifyContent="flex-start" px={2} mt={2}>
                <MDInput
                  fullWidth
                  placeholder="Description"
                  inputRef={(el) => (refs.current[`description${index}`] = el)}
                  value={subject.description}
                  onChange={(e) => handleInputChange(index, "description", e.target.value)}
                />
              </MDBox>
            </React.Fragment>
          ))}

          {/* Submit Button */}
          <MDBox display="flex" justifyContent="flex-end" px={2} mt={2}>
            <MDButton variant="contained" color="info" onClick={handleSubmit}>
              Submit
            </MDButton>
          </MDBox>
        </CardContent>
      </Card>
    </DashboardLayout>
  );
}

export default Subjects;
