import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";

import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import { profile } from "context/profile";

import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

import routes from "routes";
import { BrowserRouter as Router } from "react-router-dom";
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import { brandNameProvider } from "./utils/brandName.js";
import PrivateRoute from "./utils/privateRoute.js";
import SignIn from "layouts/authentication/sign-in";
import { isAuthenticated } from "context/isAuthenticated.js";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SnackbarProvider, useSnackbar } from "notistack";
import { ArrowDropDown } from "@mui/icons-material";
import Forgotten from "layouts/authentication/forgottenpassword";
import Herosection from "landingPage";
export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const [brandName, setBrandName] = useState("");
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);
  let [isAuth, setIsAuth] = useState(false);
  // Setting page scroll to 0 when changing the route
  let [authMessage, setAuthMessage] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);
  async function checkAuth() {
    const value = await isAuthenticated();
    setIsAuth(value);
    isAuth && setAuthMessage("Authenticated successfully");
    setTimeout(() => setIsOpen(false), 2000);
    return value;
  }
  useEffect(() => {
    checkAuth();
  }, [checkAuth]);
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <SnackbarProvider maxSnack={3}>
          <CssBaseline />
          {layout === "dashboard" && isAuth && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                brandName={brandNameProvider()}
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Configurator />
              {configsButton}
            </>
          )}
          {layout === "vr" && <Configurator />}
          {isOpen
            ? isAuth && (
                <Snackbar open={open} autoHideDuration={50}>
                  <Alert
                    severity={"success"}
                    variant="filled"
                    sx={{ width: "100%" }}
                    onClose={() => {
                      setIsOpen(!isOpen);
                    }}
                  >
                    {authMessage}
                  </Alert>
                </Snackbar>
              )
            : null}
          <Routes>
            <Route path="/reset" element={<Forgotten />} />
            <Route path="/login" element={<SignIn />} />
            <Route path="/*" element={<Herosection />} />
            <Route element={<PrivateRoute />}>{getRoutes(routes)}</Route>
          </Routes>
        </SnackbarProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
}
