import React from "react";
import PropTypes from "prop-types";
import AvatarColor from "components/ColorAvater/ColorAvater";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { Avatar } from "@mui/material";
import { Check } from "@mui/icons-material";

const AttendanceComp = (props) => {
  let { name, grade, key, selected } = props;
  name = name[0].toUpperCase() + name.substring(1);
  const CustomHr = ({ height, color }) => {
    const hrStyle = {
      height: `${height}px`,
      backgroundColor: color,
    };

    return <div className="custom-hr" style={hrStyle}></div>;
  };
  return (
    <MDTypography key={key}>
      <MDBox display="flex" flexDirection="row" mt={2} ml={1} flexA>
        <MDBox>
          <Avatar sx={{ bgcolor: selected ? "green" : AvatarColor(name) }}>
            {selected ? <Check /> : name.charAt(0)}
          </Avatar>
        </MDBox>
        &nbsp;
        <MDBox>
          <p style={{ fontSize: 14 }}>{name}</p>
        </MDBox>
      </MDBox>
      <MDTypography
        mt={-2}
        ml={-12}
        fontWeight="light"
        color="secondary"
        sx={{ fontSize: "small", textAlign: "center" }}
      >
        Grade: {grade}
      </MDTypography>

      <CustomHr height={1} color="#f0f2f5" />
    </MDTypography>
  );
};

AttendanceComp.propTypes = {
  name: PropTypes.string.isRequired,
  grade: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  height: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

export default AttendanceComp;
