import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { LoadinSpiner } from "components/LoadingSpinner";
import MessageSend from "layouts/billing/components/BroadCasting";
import BoadCastList from "layouts/billing/components/BoadCastList";
import { useState } from "react";
import { BroadcastMessage } from "utils/requests/message/createmessage";
import Snackbar from "awesome-snackbar";
function Billing() {
  let students = [];
  let braodcastJson = {
    message: "",
    receiver_list: students,
  };
  const [sending, setSending] = useState(false);
  const broadCast = async (message) => {
    braodcastJson.message = message;
    braodcastJson.receiver_list = students;
    console.log(message);
    if (students.length === 0) {
      new Snackbar(`Please select students before sending.`, {
        theme: "light",
        position: "top-center",
        style: {
          container: [
            ["background-color", "red"],
            ["color", "white"],
          ],
        },
      });
      return;
    }
    setSending(true);
    let response = await BroadcastMessage(braodcastJson);
    setSending(false);
    if (response) {
      new Snackbar(`message broadcasted successfully`, {
        theme: "light",
        position: "top-center",
        style: {
          container: [
            ["background-color", "green"],
            ["color", "white"],
          ],
        },
      });
    } else {
      new Snackbar(`Broadcasting failed!`, {
        theme: "light",
        position: "top-center",
        style: {
          container: [
            ["background-color", "red"],
            ["color", "white"],
          ],
        },
      });
    }
  };
  const addToList = (id, isAdding, isClear) => {
    console.log(isClear);
    if (!isClear) {
      if (isAdding) {
        if (typeof id !== "number") students.push(id);
      }
      if (!isAdding) {
        students = students.filter((item) => item !== id);
      }
    } else {
      students = [];
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={8}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <Grid item xs={12}>
                <MessageSend handler={broadCast} />
                {sending && <LoadinSpiner />}
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4}>
              <BoadCastList handler={addToList} />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Billing;
