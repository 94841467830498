import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import {
  FaSms,
  FaChartLine,
  FaUserFriends,
  FaGraduationCap,
  FaChalkboardTeacher,
  FaEnvelopeOpenText,
} from "react-icons/fa";

export default () => {
  const controls = useAnimation();
  const { ref: featureRef, inView: isFeatureVisible } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  useEffect(() => {
    if (isFeatureVisible) {
      controls.start("visible");
    }
  }, [controls, isFeatureVisible]);

  const features = [
    {
      icon: <FaSms size={24} />,
      title: "SMS Reporting",
      desc: "Keep parents informed with automated SMS reports on student attendance, grades, and more.",
    },
    {
      icon: <FaChartLine size={24} />,
      title: "AI Suggestions",
      desc: "Leverage AI to offer parents personalized insights and suggestions for student success.",
    },
    {
      icon: <FaGraduationCap size={24} />,
      title: "Roster Management",
      desc: "Easily manage school rosters with our comprehensive dashboard designed for simplicity and accuracy.",
    },
    {
      icon: <FaUserFriends size={24} />,
      title: "Student Performance Tracking",
      desc: "Track student performance with real-time data to help teachers and parents stay informed.",
    },
    {
      icon: <FaChalkboardTeacher size={24} />,
      title: "Teacher Bonuses",
      desc: "Reward teachers with bonuses for assisting with data encoding and administrative tasks.",
    },
    {
      icon: <FaEnvelopeOpenText size={24} />,
      title: "Parent-Teacher Communication",
      desc: "Facilitate seamless communication between parents and teachers through our integrated platform.",
    },
  ];

  return (
    <section className="py-14">
      <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
        <div className="relative max-w-2xl mx-auto sm:text-center">
          <motion.div
            ref={featureRef}
            initial={{ opacity: 0, y: 30 }}
            animate={controls}
            variants={{
              visible: { opacity: 1, y: 22, transition: { duration: 1, ease: [0.42, 0, 0.58, 1] } },
              hidden: { opacity: 0, y: 30 },
            }}
          >
            <h3 className="text-gray-800 text-3xl font-semibold sm:text-4xl">
              Let’s help power your School
            </h3>
            <p className="mt-3">
              Our platform offers a range of features designed to streamline school management and
              enhance communication between schools, parents, and teachers.
            </p>
          </motion.div>
          <div
            className="absolute inset-0 max-w-xs mx-auto h-44 blur-[118px]"
            style={{
              background:
                "linear-gradient(152.92deg, rgba(192, 132, 252, 0.2) 4.54%, rgba(232, 121, 249, 0.26) 34.2%, rgba(192, 132, 252, 0.1) 77.55%)",
            }}
          ></div>
        </div>
        <div className="relative mt-12">
          <ul className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {features.map((item, idx) => (
              <motion.li
                key={idx}
                whileHover={{ scale: 1.05, boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)" }}
                initial="hidden"
                animate={controls}
                variants={{
                  visible: {
                    opacity: 1,
                    y: 0,
                    transition: { duration: 0.8, delay: idx * 0.2, ease: [0.42, 0, 0.58, 1] },
                  },
                  hidden: { opacity: 0, y: 30 },
                }}
                className="bg-white space-y-3 p-4 border rounded-lg transform transition duration-500"
              >
                <motion.div
                  className="text-indigo-600 pb-3"
                  whileHover={{ scale: 1.2, color: "#6366f1" }} // Icon hover effect
                >
                  {item.icon}
                </motion.div>
                <h4 className="text-lg text-gray-800 font-semibold">{item.title}</h4>
                <p>{item.desc}</p>
              </motion.li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};
