import axiosInstance from "config/axios-config.js";

export const postresult = async (data) => {
  console.log(data.student_id);
  let response;
  try {
    response = await axiosInstance.post("/result", {
      exam_id: data.exam_id,
      mark: data.mark,
      subject_id: data.subject_id,
      school_id: data.school_id,
      student_id: data.student_id,
      mark_rank: data.mark_rank,
      comment: data.comment,
    });
    console.log("Response from server:", response.data);
    console.log("Data submitted!");
  } catch (error) {
    console.error("Error submitting data:", error);
  }
};
