import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import logo from "assets/images/askuala primary.png";
import Lottie from "lottie-react";
import heroSectionLottie from "assets/lottie/heroSectionLottie.json";
import selectedLottie from "assets/lottie/scroller.json";
import Team from "./team";
import Pricing from "./pricing";
import Footers from "./footers";
import ContactForm from "./contactForm";
import Features from "./features";
import { ReactTyped } from "react-typed";

export default () => {
  const [state, setState] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [scrollProgress, setScrollProgress] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const heroControls = useAnimation();
  const teamControls = useAnimation();
  const pricingControls = useAnimation();
  const contactControls = useAnimation();
  const featureControls = useAnimation();

  const [heroRef, heroInView] = useInView({ threshold: 0.2 });
  const [teamRef, teamInView] = useInView({ threshold: 0.1 });
  const [pricingRef, pricingInView] = useInView({ threshold: 0.2 });
  const [contactRef, contactInView] = useInView({ threshold: 0.2 });
  const [featureRef, featureInView] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (heroInView) heroControls.start("visible");
    if (teamInView) teamControls.start("visible");
    if (pricingInView) pricingControls.start("visible");
    if (contactInView) contactControls.start("visible");
    if (featureInView) featureControls.start("visible");
  }, [heroInView, teamInView, pricingInView, contactInView]);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);

      const totalHeight = document.body.scrollHeight - window.innerHeight;
      const progress = (window.scrollY / totalHeight) * 100;
      setScrollProgress(progress);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Smooth scroll to next section
  const scrollToNextSection = () => {
    const nextSection = document.getElementById("features");
    nextSection.scrollIntoView({ behavior: "smooth" });
  };

  const navigation = [
    { title: "Home", path: "#" },
    { title: "Features", path: "#features" },
    { title: "Team", path: "#Team" },
    { title: "Pricing", path: "#Pricing" },
    { title: "Contact us", path: "#contact" },
  ];

  // Handle click on menu item to update active index
  const handleMenuClick = (index) => {
    setActiveIndex(index); // Set the clicked item as active
  };

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          right: 0,
          width: "10px",
          height: `${scrollProgress}%`,
          backgroundColor: "#3e25f7",
          zIndex: 100,
          transition: "height 0.3s ease-out",
        }}
      />
      <style>
        {`
          ::-webkit-scrollbar {
            display: none;
          }
          scroll-behavior: smooth;
        `}
      </style>

      <nav
        className={`fixed top-0 left-0 right-0 z-10 items-center py-3 px-1 mx-auto max-w-screen-xl sm:px-8 md:flex h-20 md:justify-between transition duration-300 ${
          scrolled
            ? "bg-white bg-opacity-80 shadow-md backdrop-blur-lg"
            : "bg-transparent backdrop-blur-lg"
        }`}
      >
        <div className="flex justify-between items-center w-full">
          <div className="flex items-center space-x-6">
            <a href="#">
              <img src={logo} width={80} height={30} alt="Logo" />
            </a>

            <ul
              className={`md:flex md:items-center md:space-x-6 absolute top-16 left-0 w-full md:w-auto md:static md:bg-transparent ${
                state ? "block" : "hidden"
              } bg-white md:bg-transparent md:shadow-none shadow-md`}
            >
              {navigation.map((item, idx) => (
                <motion.li
                  className={`relative text-gray-800 cursor-pointer group py-2 px-4 md:px-0 ${
                    activeIndex === idx ? "text-[#1467b5]" : "" // Add active class if active
                  }`}
                  key={idx}
                  onClick={() => handleMenuClick(idx)} // Update active index on click
                >
                  <a href={item.path} className="relative text-base">
                    {item.title}
                    <span
                      className={`absolute left-0 bottom-[-2px] w-full h-0.5 bg-[#1467b5] transition-all duration-300 scale-x-0 origin-left group-hover:scale-x-100 ${
                        activeIndex === idx ? "scale-x-100" : "" // Keep underline for active item
                      }`}
                    ></span>
                  </a>
                </motion.li>
              ))}
            </ul>
          </div>

          <button
            className="text-gray-500 outline-none md:hidden z-20"
            onClick={() => setState(!state)}
          >
            {state ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            )}
          </button>

          <a
            href="/login"
            className="hidden md:inline-block py-2 px-5 rounded-lg font-medium text-white bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 duration-150"
          >
            Get started
          </a>
        </div>

        {state && (
          <a
            href="/login"
            className="block md:hidden mt-4 py-2 px-5 rounded-lg font-medium text-white text-center bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 duration-150"
          >
            Get started
          </a>
        )}
      </nav>

      <motion.section
        id="hero"
        className="py-10 relative"
        initial="hidden"
        animate="visible"
        variants={{
          hidden: { opacity: 0, y: 0 },
          visible: { opacity: 1, y: 50, transition: { duration: 0.8 } },
        }}
      >
        <div className="max-w-screen-xl mx-auto text-gray-600 gap-x-12 items-center justify-around overflow-hidden md:flex md:px-30">
          <div className="flex-none space-y-5 px-4 sm:max-w-lg md:px-0 lg:max-w-xl">
            <motion.h1
              className="text-sm text-indigo-600 font-medium"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.3 }}
            >
              Aim to cover the whole world
            </motion.h1>

            <ReactTyped
              className="text-4xl text-gray-800 font-extrabold md:text-20xl"
              strings={[
                "Bridge the Gap: Teachers and Parents",
                "You Know What's Better? Free Solutions for Schools!",
                "What are you waiting for? Contact Us",
              ]}
              typeSpeed={70}
              backSpeed={50}
              backDelay={2000}
              startDelay={500}
              loop
            />

            <motion.p
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.7 }}
            >
              Empower your school community with seamless communication between teachers and
              parents—all at no cost. We bridge the gap, and it’s completely free!
            </motion.p>
          </div>

          <motion.div
            className="flex-none mt-14 md:mt-0 md:max-w-xl"
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <Lottie animationData={heroSectionLottie} loop={true} />
          </motion.div>

          <motion.div
            className="absolute bottom-20 left-1/2 transform -translate-x-1/2 cursor-pointer "
            initial={{ scale: 1, x: 0 }}
            animate={{ scale: 0.5, x: -10 }}
            style={{ width: 100, height: 100 }}
            onClick={scrollToNextSection}
          >
            <Lottie animationData={selectedLottie} loop={true} width={5} height={5} />
          </motion.div>
        </div>
      </motion.section>

      {/* Team Section */}
      <motion.section ref={featureRef} id="features" initial="hidden">
        <Features />
      </motion.section>

      <motion.section ref={teamRef} id="Team" initial="hidden" animate={teamControls}>
        <Team />
      </motion.section>
      <motion.section ref={pricingRef} id="Pricing" initial="hidden" animate={pricingControls}>
        <Pricing />
      </motion.section>

      <motion.section ref={contactRef} id="contact" initial="hidden" animate={contactControls}>
        <ContactForm />
      </motion.section>

      <Footers />
    </>
  );
};
