import React, { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert"; // Import MDAlert component
import { resetPassword } from "utils/requests/user/resetPassword";
function PasswordConfirmation() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [alertContent, setAlertContent] = useState("");
  const [alertColor, setAlertColor] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const passwordHandler = (event) => {
    setPassword(event.target.value);
  };

  const confirmPasswordHandler = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSave = async () => {
    let res;
    if (password === confirmPassword) {
      res = await resetPassword(password);
      if (res.success) {
        setAlertContent("Password changed successfully");
        setAlertColor("success");
        setShowAlert(true);
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      } else {
        console.log(res);
        setAlertContent(res.message);
        setAlertColor("error");
      }
    } else {
      console.log(res);
      setAlertContent("Passwords should be match.");
      setAlertColor("error");
    }
    setShowAlert(true); // Show the alert
  };

  return (
    <MDBox>
      <FormControl sx={{ width: "100%", marginBottom: "16px" }} variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={passwordHandler}
          label="New Password"
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <FormControl sx={{ width: "100%", marginBottom: "16px" }} variant="outlined">
        <InputLabel htmlFor="outlined-adornment-confirm-password">Confirm Password</InputLabel>
        <OutlinedInput
          id="outlined-adornment-confirm-password"
          type={showPassword ? "text" : "password"}
          value={confirmPassword}
          onChange={confirmPasswordHandler}
          label="Confirm Password"
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <MDButton id="signin" variant="gradient" color="info" fullWidth onClick={handleSave}>
        Save
      </MDButton>
      {showAlert && (
        <MDAlert
          color={alertColor}
          dismissible
          sx={{
            position: "fixed",
            bottom: "16px",
            left: "16px",
            zIndex: 9999,
            width: "auto",
          }}
        >
          {alertContent}
        </MDAlert>
      )}
    </MDBox>
    // </Card>
  );
}

export default PasswordConfirmation;
