import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Bubble from "../../components/bubble/bubble"; // Assuming the Bubble component is in the same folder
import { FaCheck } from "react-icons/fa"; // Importing a check icon

function BroadcastinghistoryCard({ message, name, lname, role, status }) {
  useEffect(() => {
    // Chat bubble initialization if needed
  }, []);

  return (
    <div className="w-full flex flex-col space-y-2">
      <Bubble
        message={
          <>
            {`${name} ${lname}: ${message}`}{" "}
            {status === true ? (
              <FaCheck className="inline text-green-500" /> // Green check if status is true
            ) : (
              <FaCheck className="inline text-gray-500" /> // Gray check if status is false
            )}
          </>
        }
      />
    </div>
  );
}

BroadcastinghistoryCard.propTypes = {
  message: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  lname: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  status: PropTypes.bool.isRequired, // Status should be a boolean
};

export default BroadcastinghistoryCard;
