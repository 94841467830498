import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import PropTypes from "prop-types";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Billing page components
import AttendanceList from "./attendancelist";
import { BroadcastMessage } from "utils/requests/message/createmessage";
import Snackbar from "awesome-snackbar";
function Attendance() {
  let students = [];
  let braodcastJson = {
    message: "",
    receiver_list: students,
  };
  const broadCast = (message) => {
    braodcastJson.message = message;
    braodcastJson.receiver_list = students;
    if (students.length === 0) {
      new Snackbar(`Please select students before sending.`, {
        theme: "light",
        position: "top-center",
        style: { container: [["background-color", "red"]] },
      });
      return;
    }

    BroadcastMessage(braodcastJson);
  };
  const addToList = (id, isAdding, isClear) => {
    console.log(isClear);
    if (!isClear) {
      if (isAdding) {
        if (typeof id !== "number") students.push(id);
      }
      if (!isAdding) {
        students = students.filter((item) => item !== id);
      }
    } else {
      students = [];
    }

    console.log(students);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={8}>
        <MDBox mb={3} ml={10} mr={10}>
          <AttendanceList handler={addToList} />
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Attendance;
