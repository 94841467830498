const adminRoutes = [
  "dashboard",
  "staffs",
  "broadcast",
  "notifications",
  "profile",
  "logout",
  "students",
];
const registrarRoutes = [
  "staffs",
  "broadcast",
  "students",
  "classes",
  "subjects",
  "profile",
  "logout",
];
const teacherRoutes = ["students", "broadcast", "attendance", "profile", "logout"];

export const Role_Route = (role) => {
  if (role !== null || role !== "") {
    if (role.toLowerCase() === "admin") return adminRoutes;
    else if (role.toLowerCase() === "registrar") return registrarRoutes;
    else if (role.toLowerCase() === "teacher") return teacherRoutes;
    // else if (role.toLowerCase() === "encoder") return teacherRoutes;
    else return [];
  }
};

export const Role_Route_init = (role) => {
  if (role.toLowerCase() === "admin") return "dashboard";
  else if (role.toLowerCase() === "registrar") return "staffs";
  else if (role.toLowerCase() === "teacher") return "students";
  else return [];
};
