import axiosInstance from "config/axios-config";
import { profile } from "./profile";
import { getToken } from "config/storageController";
import { profileDataMapper } from "utils/requests/authentication";
export const isAuthenticated = async () => {
  let tokenProfile = profile.accessToken;
  let localToken = getToken();
  let isAuth = false;
  let responseMessage = "";
  if (tokenProfile === localToken || localToken != null) {
    await axiosInstance
      .post("/verify", {
        token: localToken,
      })
      .then((res) => {
        res.data.success ? (isAuth = true) : (isAuth = false);
        if (res.data.success) {
          profileDataMapper(res.data.data);
        }
        return isAuth;
      })
      .catch((error) => {
        console.log("error on verifing: ", error);
        responseMessage = error.message;
      });
    return isAuth;
  } else if (!tokenProfile) {
    const response = await axiosInstance
      .post("/verify", {
        token: tokenProfile,
      })
      .then((res) => {
        res.data.success ? (isAuth = true) : (isAuth = false);
      })
      .catch((error) => {
        responseMessage = error.message;
      });
  }
  return false;
};
