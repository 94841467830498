import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { getMessage } from "utils/requests/message/fetchmessage";
import { LoadinSpiner } from "components/LoadingSpinner";
import BroadcastinghistoryCard from "components/broadcasthistorycard/broadcastinhistorycard";
import { historymessagesGetter } from "context/historymessages";

function BroadcastHistory() {
  const [messages, setMessages] = useState([]); // Initialize as empty array
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const fetching = async () => {
      setIsFetching(true);
      await getMessage(); // Assuming this fetches all messages
      setMessages(historymessagesGetter()); // Store all messages at once
      setIsFetching(false);
    };
    fetching();
  }, []);

  return (
    <MDBox p={2} height="236px" overflow="auto">
      {isFetching ? (
        <LoadinSpiner />
      ) : messages.length === 0 ? (
        <MDTypography variant="body2" color="textSecondary" align="center">
          No messages to display
        </MDTypography>
      ) : (
        <>
          {messages.map((message) => (
            <MDBox pt={2} px={2} key={message.request_id}>
              <BroadcastinghistoryCard
                message={message.message}
                name={message.sender_name}
                date={message.sent_at}
                role={message.sender_role}
                request_id={message.request_id}
                status={true}
              />
            </MDBox>
          ))}
        </>
      )}
    </MDBox>
  );
}

export default BroadcastHistory;
