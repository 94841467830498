import axiosInstance from "config/axios-config.js";

export const postTeachers = async (data) => {
  let response = {
    success: false,
    message: "",
    data: null,
  };
  try {
    response = await axiosInstance.post("/staff", {
      first_name: data.first_name,
      last_name: data.last_name,
      phone_number: data.phone_number,
      email: data.email,
      subject_id: data.subject_id,
      educational_level: data.educational_level,
      role: data.role,
    });
    if (response.data.success) {
      response = {
        success: true,
        message: "Staff profile successfully created!",
        data: response.data.data,
      };
    }
  } catch (error) {
    response.message = error.response.data.message;
  }
  return response;
};
