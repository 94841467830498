import React, { useState, useRef, useEffect } from "react";
import { Card, CardContent, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { snackbar } from "components/awesome_snackbar/snackbar";
import { useMediaQuery } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

function Classes() {
  const [rows, setRows] = useState([{ grade: "", startSection: "", endSection: "" }]);
  const [result, setResult] = useState(null);

  const gradeInputRef = useRef(null);
  const startSectionRef = useRef(null);
  const endSectionRef = useRef(null);
  useEffect(() => {
    if (gradeInputRef.current) {
      gradeInputRef.current.focus();
    }
  }, []);

  // Handle input changes for each row
  const handleInputChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  // Add new row when plus button is clicked
  const handleAddRow = () => {
    setRows([...rows, { grade: "", startSection: "", endSection: "" }]);
  };

  // Validation functions
  const validateSection = (section) => {
    if (section.length !== 1) {
      return "You can enter just one letter.";
    }
    if (!/^[a-zA-Z]$/.test(section)) {
      return "Cannot enter numbers or special characters.";
    }
    return null;
  };

  const getCharRange = (start, end) => {
    const range = [];
    let startChar = start.toUpperCase().charCodeAt(0);
    let endChar = end.toUpperCase().charCodeAt(0);

    if (startChar > endChar) {
      return "Error! Start section must be before or equal to end section.";
    }

    for (let i = startChar; i <= endChar; i++) {
      range.push(String.fromCharCode(i));
    }
    return range;
  };

  // Handle submission of all rows
  const handleSubmit = async () => {
    const allResults = [];

    for (let i = 0; i < rows.length; i++) {
      const { grade, startSection, endSection } = rows[i];

      if (!grade || !startSection) {
        await snackbar("error", `Error in row ${i + 1}! Grade and Start Section are required.`);
        return;
      }

      const startValidationError = validateSection(startSection);
      if (startValidationError) {
        await snackbar("error", `Error in row ${i + 1}! ${startValidationError}`);
        return;
      }

      if (endSection) {
        const endValidationError = validateSection(endSection);
        if (endValidationError) {
          await snackbar("error", `Error in row ${i + 1}! ${endValidationError}`);
          return;
        }

        const sectionRange = getCharRange(startSection, endSection);
        if (typeof sectionRange === "string") {
          await snackbar("error", `Error in row ${i + 1}! ${sectionRange}`);
          return;
        }

        const classDetails = {
          grade: parseInt(grade),
          sectionRange,
        };

        allResults.push(classDetails);
      } else {
        const classDetails = {
          grade: parseInt(grade),
          sectionRange: [startSection.toUpperCase()],
        };

        allResults.push(classDetails);
      }
    }

    // Set all results to state
    setResult(allResults);
    await snackbar("success", "Success! Classes created successfully.");

    // Clear inputs and reset to only one row
    setRows([{ grade: "", startSection: "", endSection: "" }]);

    if (gradeInputRef.current) {
      gradeInputRef.current.focus(); // Focus back on the first input
    }

    console.log("Submitted Class Details:", allResults);
  };

  // Handle Enter key for moving between fields
  const handleKeyDown = (e, refToFocusNext) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (refToFocusNext) {
        refToFocusNext.current.focus();
      } else {
        handleSubmit();
      }
    }
  };

  // Media query for responsive design
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card sx={{ height: "auto", width: "100%" }}>
        <CardContent>
          <MDBox
            pt={2}
            px={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <MDTypography variant="h6" fontWeight="medium">
              Create Class
            </MDTypography>
            {/* Add Icon Button for adding more rows */}
            <IconButton color="info" onClick={handleAddRow}>
              <AddIcon />
            </IconButton>
          </MDBox>

          {/* Mapping over each row of inputs */}
          {rows.map((row, index) => (
            <MDBox
              key={index}
              display="flex"
              flexDirection={isSmallScreen ? "column" : "row"} // Column on small screens, row on larger
              gap={2}
              px={2}
              mb={2}
            >
              <MDInput
                type="number"
                label="Grade"
                placeholder="Enter the grade (e.g., 1, 2, 3)"
                value={row.grade}
                onChange={(e) => handleInputChange(index, "grade", e.target.value)}
                inputRef={gradeInputRef} // Focus on this input initially
                onKeyDown={(e) => handleKeyDown(e, startSectionRef)} // Focus on first input initially
                InputLabelProps={{ shrink: true }}
                required
                fullWidth
              />
              <MDInput
                type="text"
                label="Start Section"
                placeholder="Enter the start section (e.g., A)"
                value={row.startSection}
                onChange={(e) => handleInputChange(index, "startSection", e.target.value)}
                inputRef={startSectionRef} // Ref for the second input field
                onKeyDown={(e) => handleKeyDown(e, endSectionRef)}
                InputLabelProps={{ shrink: true }}
                required
                fullWidth
              />
              <MDInput
                type="text"
                label="End Section (Optional)"
                placeholder="Enter the end section (e.g., D)"
                value={row.endSection}
                inputRef={endSectionRef}
                onChange={(e) => handleInputChange(index, "endSection", e.target.value)}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </MDBox>
          ))}

          {/* Submit button */}
          <MDBox display="flex" justifyContent="flex-end" px={2} mt={2}>
            <MDButton variant="contained" color="info" onClick={handleSubmit}>
              Submit
            </MDButton>
          </MDBox>
        </CardContent>
      </Card>
    </DashboardLayout>
  );
}

export default Classes;
