import React, { useState } from "react";
import { motion } from "framer-motion";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import emailjs from "emailjs-com"; // Import EmailJS

export default function ContactForm() {
  // State for phone input
  const [phone, setPhone] = useState("");
  // State to check if the form is submitted
  const [isSubmitted, setIsSubmitted] = useState(false);
  // State for form data
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });

  // Handle input changes
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_73vwsoo",
        "template_7hok6rr",
        {
          from_name: `${formData.firstName} ${formData.lastName}`,
          email: formData.email,
          phone: phone,
          message: formData.message,
        },
        "e8raNftMpJJFicd6i"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setIsSubmitted(true);
        },
        (error) => {
          console.log("FAILED...", error);
        }
      );
  };

  return (
    <main className="pt-24 pb-14">
      <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="hidden md:block"
          >
            <img
              src="https://cdni.iconscout.com/illustration/premium/thumb/contact-us-illustration-download-in-svg-png-gif-file-formats--call-logo-laptop-helping-customer-service-pack-network-communication-illustrations-2912018.png"
              alt="Contact Us Illustration"
              className="w-full h-auto"
            />
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="space-y-5"
          >
            <div className="max-w-lg mx-auto space-y-3 sm:text-center">
              <h3 className="text-indigo-600 font-semibold">Contact</h3>
              <p className="text-gray-800 text-3xl font-semibold sm:text-4xl">Get in touch</p>
              <p>We’d love to hear from you! Please fill out the form below.</p>
            </div>

            <div className="mt-12 max-w-lg mx-auto">
              {isSubmitted ? (
                // Show "Thank you" message after form submission
                <div className="bg-white shadow-md rounded-lg p-8">
                  <h3 className="text-2xl font-semibold text-center text-indigo-600">Thank You!</h3>
                  <p className="mt-4 text-center text-gray-600">
                    We’ve received your message and will get back to you as soon as possible.
                  </p>
                </div>
              ) : (
                <form onSubmit={handleSubmit} className="space-y-5">
                  <div className="flex flex-col items-center gap-y-5 gap-x-6 [&>*]:w-full sm:flex-row">
                    <div>
                      <label className="font-medium">First name</label>
                      <input
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        required
                        className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                      />
                    </div>
                    <div>
                      <label className="font-medium">Last name</label>
                      <input
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        required
                        className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                      />
                    </div>
                  </div>
                  <div>
                    <label className="font-medium">Email</label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                      className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                    />
                  </div>
                  <div>
                    <label className="font-medium">Phone number</label>
                    <div className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg">
                      <PhoneInput
                        id="phone_input"
                        country={"et"}
                        value={phone}
                        placeholder="+ (251) 000-111-222"
                        onChange={(number) => setPhone(number)}
                        preferredCountries={["et", "ke", "dj", "sd"]}
                        enableSearch={true}
                        countryCodeEditable={false}
                        inputStyle={{
                          backgroundColor: "transparent",
                          border: "none",
                          boxShadow: "none",
                          width: "100%",
                        }}
                        autocompleteSearch={true}
                      />
                    </div>
                  </div>
                  <div>
                    <label className="font-medium">Message</label>
                    <textarea
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      required
                      className="w-full mt-2 h-36 px-3 py-2 resize-none appearance-none bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                    ></textarea>
                  </div>
                  <button className="w-full px-4 py-2 text-white font-medium bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-600 rounded-lg duration-150">
                    Submit
                  </button>
                </form>
              )}
            </div>
          </motion.div>
        </div>
      </div>
    </main>
  );
}
