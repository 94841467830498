import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import MDButton from "components/MDButton";
import { Registration } from "components/Registration";
import MDInput from "components/MDInput";
import Icon from "@mui/material/Icon";
import StudentAdd from "../../OverLayForm";
import { FileUpload } from "components/FileUpload";
import { ArrowBackIos } from "@mui/icons-material";
import { postTeachers } from "utils/requests/teachers/teacherspost";
import { profile } from "context/profile";
import PropTypes from "prop-types";
import "react-phone-input-2/lib/style.css";
import AddingComp from "components/AddingComponent";
function Tables(props) {
  const routename = props.routename;
  const { columns, rows } = authorsTableData(routename);
  const [isBoxVisible, setIsBoxVisible] = useState(true);

  const handleClick = () => {
    setIsBoxVisible(!isBoxVisible);
  };
  // == "staffs"
  console.log(routename.toLowerCase() == "teachers");
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={10}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                display="flex"
                justifyContent="space-around"
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  {props.routename === "Teachers" ? "Staffs" : props.routename}
                </MDTypography>

                <IconButton style={{ padding: 0 }} onClick={handleClick}>
                  {isBoxVisible ? (
                    <Registration isVisible={true} />
                  ) : (
                    <Registration isVisible={false} />
                  )}
                </IconButton>
              </MDBox>
              {isBoxVisible && (
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              )}
              {!isBoxVisible && profile.role !== "teacher" && (
                <AddingComp isStaff={routename.toLowerCase() == "teachers"} />
              )}
              {!isBoxVisible &&
                routename.toLowerCase() === "students" &&
                profile.role === "teacher" && <StudentAdd />}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {isBoxVisible && <Footer />}
    </DashboardLayout>
  );
}

Tables.propTypes = {
  routename: PropTypes.string.isRequired,
};

export default Tables;
