import axiosInstance from "config/axios-config.js";

export const creatSubject = async (data) => {
  let response = {
    success: false,
    message: "",
    data: null,
  };
  try {
    response = await axiosInstance.post("/subject", {
      name: data.name,
      description: data.description,
      grade_start: data.grade_start,
      grade_end: data.grade_end,
    });
    if (response.data.success) {
      response = {
        success: true,
        message: "Staff profile successfully created!",
        data: response.data.data,
      };
    }
  } catch (error) {
    response.message = error.response.data.message;
  }
  return response;
};
