import axiosInstance from "config/axios-config";
import { profile } from "context/profile";
import { Outlet, useNavigate } from "react-router-dom";
import { useState } from "react";
import { LoadinSpiner } from "components/LoadingSpinner";
export const BroadcastMessage = async (braodcastJson) => {
  let response;
  braodcastJson.sender_id = profile.staff_id;
  console.log(braodcastJson);
  try {
    response = await axiosInstance
      .post("/message", braodcastJson)
      .then((res) => {
        console.log(res);
        if (res.data.success) return true;
        else return false;
      })
      .catch((error) => {
        console.log(error);
        response = error.response.data.success;
        return false;
      });
  } catch (error) {
    console.log(error);
    return false;
  }
  return response;
};
