import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import { dashboardReader } from "utils/requests/crud";
import { useEffect, useState } from "react";
import { fetchDashboard } from "utils/requests/others/dashboard";
import { getNotification } from "../../utils/requests/notification/notification";
import { notificationGetter } from "context/notification";
import { LoadinSpiner } from "components/LoadingSpinner";
function Dashboard() {
  const { sales, tasks } = reportsLineChartData;
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const navigateTostudents = () => {
    navigate("/students", { replace: true });
  };
  const navigateToteachers = () => {
    navigate("/staffs", { replace: true });
  };
  const [dataJson, setDataJson] = useState({});
  const setCardBoard = (data) => {
    setDataJson(data.data);
  };
  useEffect(() => {
    const fetchData = async () => {
      await fetchDashboard(setCardBoard);
      await getNotification();
      setNotifications(notificationGetter());
    };
    fetchData();
  }, []);
  if (isLoading) return <LoadinSpiner />;
  else
    return (
      <DashboardLayout>
        <DashboardNavbar notificationCounter={notifications} notificationLists={notifications} />
        <MDBox py={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <button
                  onClick={() => navigateTostudents()}
                  style={{
                    all: "unset",
                    display: "block",
                    cursor: "pointer",
                    width: "100%",
                  }}
                >
                  <ComplexStatisticsCard
                    color="dark"
                    icon="weekend"
                    title="Students"
                    count={dataJson.students}
                    percentage={{
                      color: "success",
                      amount: " ",
                      label: "this year",
                    }}
                  />
                </button>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <button
                  onClick={() => navigateToteachers()}
                  style={{
                    all: "unset",
                    display: "block",
                    cursor: "pointer",
                    width: "100%",
                  }}
                >
                  <ComplexStatisticsCard
                    icon="leaderboard"
                    title="Stuff"
                    count={dataJson.teachers}
                    percentage={{
                      color: "success",
                      amount: " ",
                      label: "this year",
                    }}
                  />
                </button>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="store"
                  title="Class"
                  count={dataJson.classRooms}
                  percentage={{
                    color: "success",
                    amount: "+2% ",
                    label: "than yesterday",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="person_add"
                  title="Revenu"
                  count={`+${dataJson.revenue}`}
                  percentage={{
                    color: "success",
                    amount: "+3%",
                    label: "than last month",
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
          <MDBox mt={4.5}>
            <div>
              <container>
                <MDBox mb={3}>
                  <ReportsLineChart
                    color="info"
                    title="Yearly Students Number"
                    description={
                      <>
                        (<strong>+{dataJson.increaseRate}</strong>) increase in this year.
                      </>
                    }
                    date="updated 4 min ago"
                    chart={tasks}
                  />
                </MDBox>
              </container>
            </div>
          </MDBox>
        </MDBox>
        <Footer />
      </DashboardLayout>
    );
}

export default Dashboard;
