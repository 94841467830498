import { axiosInstance } from "config/axios-config";

export const resetPassword = async (password) => {
  let res = {
    data: null,
    success: false,
    message: "No response",
  };
  try {
    let token_reseter = localStorage.getItem("reset-token");
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token_reseter}`;
    const response = await axiosInstance.post("/account/reset", {
      newPassword: password,
    });
    res = response.data;
    console.log(res);
    return res;
  } catch (error) {
    res = error.response.data;
    return res;
  }
};

export const verifyOtp = async (phone, otp) => {
  let res = {
    data: null,
    success: false,
    message: "No reponse",
  };
  try {
    let axiosAuth = axiosInstanceAuth().axiosInstance;
    const response = await axiosAuth.post("/account/verify", {
      phone_number: phone,
      otp: otp,
    });
    console.log(response);
    res = response.data;
    return res;
  } catch (err) {
    console.log(err.response.data);
    res = err.response.data;
    return res;
  }
};
