import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { RingLoader } from "react-spinners"; // You can use any spinner library or create a custom one

export default () => {
  const [loading, setLoading] = useState(true);

  const plans = [
    {
      name: "Basic plan",
      desc: "A foundational plan to improve school communication and administration.",
      price: "Free",
      isMostPop: false,
      features: [
        "Roster Provider",
        "SMS Reporting for Parents",
        "Attendance System",
        "School Admin Dashboard",
      ],
    },
    {
      name: "Standard",
      desc: "A comprehensive plan with enhanced communication and resource access.",
      price: "2,599 ETB / mo",
      isMostPop: true,
      features: [
        "All Basic plan features",
        "Question Bank Service for Worksheets",
        "Real-Time Performance Updates",
        "Parent-Teacher Communication Tools",
        "Resource Sharing Platform",
      ],
    },
    {
      name: "Advanced",
      desc: "An advanced plan for complete administrative efficiency and student support.",
      price: "5,099 ETB / mo",
      isMostPop: false,
      features: [
        "All standard plan features",
        "Data Encoding Mechanism with Excel Sheet Encoding",
        "We will hire our Encoder",
        "Automated Parent Portal Analysis",
        "Machine Learning Suggestions for Parents",
      ],
    },
  ];

  // Simulate loading state
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  // Framer Motion Variants for animation
  const cardVariants = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
  };

  return (
    <section className="pt-24 pb-14">
      <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
        <div className="relative max-w-xl mx-auto sm:text-center">
          <h3 className="text-gray-800 text-3xl font-semibold sm:text-4xl">
            Pricing for all sizes
          </h3>
          <div className="mt-3 max-w-xl">
            <p>
              Askuala Link provides comprehensive school management solutions, tailored to meet the
              needs of schools of all sizes. Our platform is designed to enhance the efficiency and
              effectiveness of school administration.
            </p>
          </div>
        </div>

        {/* Show loading spinner during loading state */}
        {loading ? (
          <div className="flex justify-center items-center h-64">
            <RingLoader color="#4f46e5" size={60} />
          </div>
        ) : (
          <motion.div
            className="mt-16 justify-center gap-6 sm:grid sm:grid-cols-2 sm:space-y-0 lg:grid-cols-3"
            initial="hidden"
            animate="visible"
            variants={cardVariants}
          >
            {plans.map((item, idx) => (
              <motion.div
                key={idx}
                className={`relative flex-1 flex items-stretch flex-col rounded-xl border-2 transition-transform transform hover:scale-105 hover:shadow-xl duration-300 ${
                  item.isMostPop ? "mt-10" : ""
                }`}
                variants={cardVariants}
              >
                {item.isMostPop && (
                  <span className="w-32 absolute -top-5 left-0 right-0 mx-auto px-3 py-2 rounded-full border shadow-md bg-white text-center text-gray-700 text-sm font-semibold">
                    Most popular
                  </span>
                )}
                <div className="p-8 space-y-4 border-b">
                  <span className="text-indigo-600 font-medium">{item.name}</span>
                  <div className="text-gray-800 text-3xl font-semibold">
                    {item.price}
                    <span className="text-xl text-gray-600 font-normal"></span>
                  </div>
                  <p>{item.desc}</p>
                  <button className="px-3 py-3 rounded-lg w-full font-semibold text-sm duration-150 text-white bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700">
                    Get Started
                  </button>
                </div>
                <ul className="p-8 space-y-3">
                  <li className="pb-2 text-gray-800 font-medium">
                    <p>Features</p>
                  </li>
                  {item.features.map((featureItem, idx) => (
                    <li key={idx} className="flex items-center gap-5">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-indigo-600"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"></path>
                      </svg>
                      {featureItem}
                    </li>
                  ))}
                </ul>
              </motion.div>
            ))}
          </motion.div>
        )}
      </div>
    </section>
  );
};
