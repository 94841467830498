import { useEffect, useState } from "react";

// react-router-dom components
import { Routes, Route, Link, NavLink, Navigate, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import { pink } from "@mui/material/colors";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
// Images
import bgImage from "assets/images/signIn-bg.jpg";
import { userLogin } from "utils/requests/authentication";
import { profile } from "context/profile";
import { Role_Route, Role_Route_init } from "utils/role_route";
import { isAuthenticated } from "context/isAuthenticated";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isAuth, setIsAuth] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [error, setError] = useState("");
  const navigator = useNavigate(); // Use a more descriptive name

  const handleSetRememberMe = () => {
    const newValue = !rememberMe;
    setRememberMe(newValue);
    localStorage.setItem("rememberMe", JSON.stringify(newValue)); // Store preference in local storage
  };

  const emailChangeHandler = (event) => {
    setEmail(event.target.value);
    setEmailError("");
    setError("");
  };

  const passwordHandler = (event) => {
    setPassword(event.target.value);
    setPasswordError("");
    setError(""); // Clear password error when the password is changed
  };

  useEffect(() => {
    if (isAuth) {
      const route_list = Role_Route(profile.role);
      console.log(Role_Route_init(profile.role));
      navigator(`/${Role_Route_init(profile.role)}`, { replace: true });
    }
  }, [isAuth]);

  useEffect(() => {
    async function checkAuth() {
      const value = await isAuthenticated();
      setIsAuth(value);
    }

    checkAuth();
  }, [isAuth]);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  useEffect(() => {
    const rememberMePreference = JSON.parse(localStorage.getItem("rememberMe"));
    if (rememberMePreference) {
      setRememberMe(rememberMePreference);
      if (rememberMePreference) {
        const storedPassword = localStorage.getItem("password");
        const storedEmail = localStorage.getItem("email");
        if (storedPassword) {
          setPassword(storedPassword);
          setEmail(storedEmail);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (rememberMe) {
      localStorage.setItem("password", password);
      localStorage.setItem("email", email);
    }
  }, [password, rememberMe]);
  const handleSignin = async (e) => {
    if (email === "") {
      setEmailError("Email is required.");
    }
    if (password === "") {
      setPasswordError("Password is required.");
    }

    if (email !== "" && password !== "") {
      const response = await userLogin(email, password);
      setIsAuth(response);
      if (response) {
        // const route_list = Role_Route(profile.role);
      } else if (!response) {
        setError("Incorrect email or password.");
      }
    }
  };

  const alertContent = (name) => (
    <MDTypography
      variant="body2"
      color="white"
      fontWeight="light"
      fontSize="default"
      textAlign="center"
    >
      {error}
    </MDTypography>
  );

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <Icon fontSize="large" sx={{ color: pink[50] }}>
            person
          </Icon>
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                onChange={emailChangeHandler}
                value={email}
                fullWidth
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    document.getElementById("outlined-adornment-password").focus();
                  }
                }}
              />
              {emailError && (
                <MDTypography variant="body2" color="error" fontWeight="light" fontSize="default">
                  {emailError}
                </MDTypography>
              )}
            </MDBox>
            <MDBox mb={2}>
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={passwordHandler}
                  label="Password"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      document.getElementById("signin").focus();
                    }
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>

              {passwordError && (
                <MDTypography variant="body2" color="error" fontWeight="light" fontSize="default">
                  {passwordError}
                </MDTypography>
              )}
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                id="signin"
                variant="gradient"
                color="info"
                fullWidth
                onClick={handleSignin}
              >
                Sign in
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography
                component={Link}
                to="/reset"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                Forgotten password?
              </MDTypography>
              {error && (
                <MDAlert color="primary" dismissible>
                  {alertContent("primary")}
                </MDAlert>
              )}
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
