import { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import NotificationCard from "components/notificationcard";
import { notificationGetter } from "context/notification";
import { getNotification } from "utils/requests/notification/notification";
import { LoadinSpiner } from "components/LoadingSpinner";
import { formatDate } from "date-fns";
function Notifications() {
  const [successSB, setSuccessSB] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [isFetching, setIsfetching] = useState(false);
  useEffect(() => {
    const fetching = async () => {
      setIsfetching(true);
      await getNotification();
      setNotifications(notificationGetter());
      setIsfetching(false);
    };
    fetching();
  }, []);

  const notificatioBuilder = () => {
    return notifications.map((notification) => {
      return (
        <MDBox pt={2} px={2} key={notification.request_id}>
          <NotificationCard
            message={notification.message}
            name={notification.sender_name}
            date={notification.sent_at}
            role={notification.sender_role}
            request_id={notification.request_id}
          />
        </MDBox>
      );
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox p={2}>
                <MDTypography variant="h5">Alerts</MDTypography>
                {isFetching ? <LoadinSpiner /> : notificatioBuilder()}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Notifications;
