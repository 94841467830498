/* eslint-disable react/prop-types */

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import Teachers from "./teachersData";
import { Avatar } from "@mui/material";
import AvatarColor from "components/ColorAvater/ColorAvater";
// Images
import { getTeachers } from "utils/requests/teachers/teacher";
import { useEffect, useState } from "react";
import { LoadinSpiner } from "components/LoadingSpinner";
import PropTypes from "prop-types";
import { profile } from "context/profile";
import { getStudents } from "utils/requests/students/studentsget";
import Students from "layouts/billing/components/StudentList/ListOfStudent/Listofstudents";
import { text } from "@fortawesome/fontawesome-svg-core";
import { Style } from "@mui/icons-material";
import { red } from "@mui/material/colors";

export default function data(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [fetched, setFetched] =
    props.toLowerCase() === "teachers" ? useState(Teachers) : useState(Students);
  const Author = ({ name, email, phone_number, subject_id }) => (
    <MDBox
      display="flex"
      alignItems="center"
      lineHeight={1}
      onClick={() => handleRowClick(name, email, phone_number, subject_id)}
    >
      <Avatar sx={{ bgcolor: AvatarColor(name) }}>{name.charAt(0)}</Avatar>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        let fetchedTeachers =
          props.toLowerCase() === "teachers" ? await getTeachers() : await getStudents();
        setFetched(fetchedTeachers.data.data);
        console.log(fetchedTeachers.data.data);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [props]);

  const handleRowClick = (rowData) => {
    console.log(rowData.name);
    // processData(rowData);
  };
  const firstLetterCapitalizer = (name) => {
    if (typeof name === "string" && name.length > 0) {
      console.log("===================================================");
      console.log(name);
      return name.charAt(0).toUpperCase() + name.slice(1);
    }
    return "";
  };
  const teacherHeader = headerIdentifier(props, profile.role);
  return {
    columns: [
      { Header: teacherHeader[0], accessor: "teacher", width: "45%", align: "left" },
      { Header: teacherHeader[1], accessor: "phone_No", align: "left" },
      { Header: teacherHeader[2], accessor: "subject", align: "center" },
      { Header: teacherHeader[3], accessor: "represented_class", align: "center" },
      { Header: teacherHeader[4], accessor: "teaching_class", align: "center" },
      { Header: teacherHeader[5], accessor: "header5", align: "center" },
    ],

    rows: fetched.map((teacher) => ({
      teacher: (
        <Author
          name={`${firstLetterCapitalizer(teacher.first_name)} ${teacher.last_name}`}
          email={teacher.email}
          phone_number={teacher.phone_number}
          subject_id={teacher.subject_id}
        />
      ),
      phone_No: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {props.toLowerCase() === "students" ? teacher.grade : teacher.phone_number}
        </MDTypography>
      ),
      subject: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {props.toLowerCase() === "students"
            ? firstLetterCapitalizer(teacher.section)
            : teacher.subject_id}
        </MDTypography>
      ),
      represented_class: (
        <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
          {props.toLowerCase() === "students" &&
          (profile.role.toLowerCase() === "admin" || profile.role.toLowerCase() === "registrar")
            ? teacher && teacher.parent && teacher.parent.first_name
              ? teacher.parent.first_name
              : "Undefined"
            : profile.role.toLowerCase() === "teacher" || profile.role.toLowerCase() === "encoder"
            ? "AVG"
            : teacher.represent_grade === null
            ? "No't Assigned"
            : teacher.represent_grade}
        </MDTypography>
      ),
      teaching_class: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {props.toLowerCase() === "students" &&
          (profile.role.toLowerCase() === "admin" || profile.role.toLowerCase() === "registrar")
            ? teacher && teacher.parent && teacher.parent.phone_number
              ? teacher.parent.phone_number
              : "Undefined"
            : profile.role.toLowerCase() === "teacher" || profile.role.toLowerCase() === "encoder"
            ? "Rank"
            : teacher.represent_section === null
            ? "No't Assigned"
            : teacher.represent_section}
        </MDTypography>
      ),
      header5: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {props.toLowerCase() === "students" &&
          (profile.role.toLowerCase() === "admin" || profile.role.toLowerCase() === "registrar") ? (
            <span style={{ color: "#E6E6E6" }}>not yet</span>
          ) : profile.role.toLowerCase() === "teacher" ||
            profile.role.toLowerCase() === "encoder" ? (
            "attendance-avg"
          ) : teacher.email === null ? (
            "dont have email"
          ) : (
            teacher.role
          )}
        </MDTypography>
      ),
    })),
  };
}

export const headerIdentifier = (routename, role) => {
  const TeacherHeader = ["Teacher", "Tel no.", "Subject", "Rep.Grade", "Section", "Role"];
  const StudentToRegistrar = [
    "Student",
    "Grade",
    "Section",
    "Parent name",
    "parent Tel. no",
    "Payment",
  ];
  const StudentToGardian = [
    "Student",
    "Grade",
    "Section",
    "Average",
    "Rank",
    "Attendance Percentage",
  ];

  if (routename.toLowerCase() === "teachers") return TeacherHeader;
  else if (
    routename.toLowerCase() === "students" &&
    (role.toLowerCase() === "admin" || role.toLowerCase() === "registrar")
  )
    return StudentToRegistrar;
  else if (routename.toLowerCase() === "students" && role.toLowerCase() === "teacher")
    return StudentToGardian;
};
