import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import AttendanceComp from "./attendancecomponent";
import { useEffect, useState } from "react";
import { FilterAlt } from "@mui/icons-material";
import { getStudents } from "utils/requests/students/studentsget";
import { profile } from "context/profile";
import PropTypes from "prop-types";
import { snackbar } from "components/awesome_snackbar/snackbar";
import { postattendance } from "utils/requests/attendance/attendancepost";

function AttendanceList(props) {
  const { handler } = props;
  const [selectedItems, setSelectedItems] = useState([]);
  const [fetched, setFetched] = useState([]);
  const [click, setClick] = useState(false);
  const [isSpecificClass, setIsSpecificClass] = useState(false);

  // Fetch students list when the component mounts
  useEffect(() => {
    const fetch = async () => {
      let students = await getStudents();
      setFetched(students.data.data);
    };
    fetch();
  }, []);

  // Handle student selection
  const handleClick = (index, student) => {
    if (selectedItems.includes(index)) {
      setSelectedItems(selectedItems.filter((item) => item !== index));
      handler(student.Student_id, false);
    } else {
      handler(student.Student_id, true);
      setSelectedItems([...selectedItems, index]);
    }
  };

  const clickhandler = () => {
    setClick(!click);
    setIsSpecificClass(false);
  };

  const checkISelected = (index) => {
    handler(index);
    return selectedItems.includes(index);
  };

  const handleSubmit = async () => {
    const currentDate = new Date();

    const attendanceRecords = fetched
      .map((student, index) => {
        if (selectedItems.includes(index)) {
          return {
            date: currentDate.toISOString().split("T")[0], // Format: YYYY-MM-DD
            time: currentDate.toTimeString().split(" ")[0], // Format: HH:MM:SS
            student_id: student.Student_id,
            staff_id: profile.staff_id,
            type: selectedItems.includes(index) ? "absent" : "tardy",
          };
        } else {
          return null;
        }
      })
      .filter((record) => record !== null);

    console.log("Attendance Records:", attendanceRecords);

    for (const record of attendanceRecords) {
      const response = await postattendance(record);
      console.log(`myresponse ${response.success}, msg: ${response.message}`);
    }
  };

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography variant="h6" fontWeight="medium">
          Choose Recipients
        </MDTypography>
        <MDButton variant="outlined" color="info" size="small" onClick={clickhandler}>
          View All &nbsp;
          <FilterAlt style={{ fontWeight: "bold" }} />
        </MDButton>
      </MDBox>
      <MDBox p={2}>
        {!click && !isSpecificClass && (
          <MDBox component="ul" display="flex" flexDirection="column" p={0} mt={0}>
            {fetched.map((student, index) => {
              return (
                <button
                  onClick={() => handleClick(index, student)}
                  key={index}
                  style={{
                    border: "none",
                    backgroundColor: selectedItems.includes(index) ? "#f0f2f5" : "transparent",
                    outline: "none",
                    boxShadow: "none",
                    marginTop: 4,
                  }}
                >
                  <AttendanceComp
                    name={student.first_name}
                    grade={student.grade}
                    selected={checkISelected(index)}
                  />
                </button>
              );
            })}
          </MDBox>
        )}
        <MDButton
          onClick={handleSubmit} // Trigger submit action
          variant="gradient"
          color="info"
          fullWidth
        >
          Submit
        </MDButton>
      </MDBox>
    </Card>
  );
}

AttendanceList.propTypes = {
  handler: PropTypes.func.isRequired,
};

export default AttendanceList;
