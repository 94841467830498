import React, { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import PropTypes from "prop-types";
function List(props) {
  let { handler } = props;
  let { updateSelectedItems, uiUpdater, hideMenu, menuSelected, specificClassUI, clear } = props;
  return (
    <MDBox component="div" display="flex" flexDirection="column" p={0} m={0}>
      <MDButton
        onClick={() => {
          updateSelectedItems("all", true);
          uiUpdater();
          menuSelected(false);
        }}
      >
        All students
      </MDButton>
      <MDButton
        onClick={() => {
          specificClassUI(true);
          menuSelected(false);
        }}
      >
        Specific class
      </MDButton>
      <MDButton
        onClick={() => {
          updateSelectedItems([], true, true);
          clear();
          uiUpdater();
          menuSelected(false);
        }}
      >
        Custom Student
      </MDButton>
    </MDBox>
  );
}
List.propTypes = {
  handler: PropTypes.func.isRequired,
  menuSelected: PropTypes.func.isRequired,
  uiUpdater: PropTypes.func.isRequired,
  updateSelectedItems: PropTypes.func.isRequired,
  hideMenu: PropTypes.func.isRequired,
  specificClassUI: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired,
};
export default List;
