import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import { Badge } from "@mui/material";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";
import { profile } from "context/profile";
// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import { notificationGetter } from "context/notification";
import { Box } from "@mui/material";
import { ListItem, ListItemAvatar, Avatar, ListItemText, Typography } from "@mui/material";
import { formatDistanceToNow } from "date-fns";
import { getNotification } from "utils/requests/notification/notification";

function DashboardNavbar({ absolute, light, isMini, notificationCounter }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const [notifications, setNotifications] = useState([]);
  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }
    const fetchData = async () => {
      await getNotification();
      setNotifications(notificationGetter());
    };
    fetchData();
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }
    window.addEventListener("scroll", handleTransparentNavbar);
    handleTransparentNavbar();

    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      PaperProps={{
        style: { maxHeight: "200px", width: "250px", overflowY: "auto" },
      }}
    >
      {notifications.length ?? 0 > 0 ? (
        notifications.slice(0, 3).map((notification) => (
          <Box key={notification.request_id} px={2} py={1} width={300}>
            <NotificationMiniCard
              message={notification.message}
              name={notification.sender_name}
              date={notification.sent_at}
              role={notification.sender_role}
            />
          </Box>
        ))
      ) : (
        <Box px={2} py={1}>
          No new notifications.
        </Box>
      )}
    </Menu>
  );
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox pr={1}>
              <MDInput label="Search here" />
            </MDBox>
            <MDBox color={light ? "white" : "inherit"}>
              <Link to="/profile">
                <IconButton sx={navbarIconButton} size="small" disableRipple>
                  <Icon sx={iconsStyle}>account_circle</Icon>
                </IconButton>
              </Link>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon sx={iconsStyle}>settings</Icon>
              </IconButton>
              {profile.role === "admin" ? (
                // <Link to={"/notifications"}>
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenMenu}
                >
                  <Badge badgeContent={notifications.length} color="error" showZero={true}>
                    <Icon sx={iconsStyle}>notifications</Icon>
                  </Badge>
                </IconButton>
              ) : (
                // </Link>
                ""
              )}
              {profile.role === "admin" ? renderMenu() : <Box></Box>}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
  notificationCounter: PropTypes.number,
};

export default DashboardNavbar;

const NotificationMiniCard = ({ message, name, date, avatarUrl }) => {
  return (
    <Link to="/notifications" style={{ textDecoration: "none" }}>
      <ListItem
        sx={{
          padding: "12px 16px",
          display: "flex",
          alignItems: "center",
          backgroundColor: "#fff",
          borderRadius: "10px",
          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
          marginBottom: "12px",
          width: "100%",
          "&:hover": {
            backgroundColor: "#f5f5f5",
          },
        }}
      >
        {/* Avatar with sender's profile image */}
        <ListItemAvatar>
          <Avatar src={avatarUrl} alt={name} sx={{ width: 48, height: 48 }} />
        </ListItemAvatar>

        {/* Notification details */}
        <ListItemText
          primary={
            <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <p style={{ fontSize: "12px", fontWeight: "bold" }}>{name} </p>
            </Box>
          }
          secondary={
            <Box>
              <Typography variant="body2" sx={{ color: "#555" }}>
                Send you broadcasting Request
              </Typography>
              <Typography variant="caption" sx={{ color: "#999" }}>
                {formatDistanceToNow(new Date(date))} ago
              </Typography>
            </Box>
          }
        />

        {/* Action Icon */}
        <IconButton
          edge="end"
          aria-label="notifications"
          sx={{ marginLeft: "auto", color: "#333" }}
        >
          <Icon>chevron_right</Icon>
        </IconButton>
      </ListItem>
    </Link>
  );
};

NotificationMiniCard.propTypes = {
  message: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string, // New prop for avatar image URL
};
